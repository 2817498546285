/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
    Card,
    CardContent,
    CardGroup,
    CardHeader,
    Checkbox,
    List,
    ListItem,
} from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery'
import { MOBILE_THRESHOLD } from '../../../../constants'

const cardStyle = css`
    &.ui.card > .content {
        flex-grow: 0;
    }
`

const Options = ({options, onChange, ...props}) => {
    const [t] = useTranslation()
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`)

    return (
      <div
        className={props.className}
        css={css`
            display: flex;
            flex-direction: column;
            gap: 1rem;
        `}
      >
          <CardGroup itemsPerRow={isMobile ? 1 : 3}>
              <Card
                color={options.performance.display ? 'teal' : undefined}
                css={cardStyle}
              >
                  <CardContent>
                      <CardHeader>
                          <Checkbox
                            toggle
                            label={t('reports.performance_configuration.options.performance.display')}
                            checked={options.performance.display}
                            onChange={() => {
                                onChange({
                                    ...options,
                                    performance: {
                                        ...options.performance,
                                        display: !options.performance.display,
                                    },
                                })
                            }}
                          />
                      </CardHeader>
                  </CardContent>
                  <CardContent>
                      {t('reports.performance_configuration.options.performance.details')}
                  </CardContent>
              </Card>

              <Card
                color={options.bestsellers.display ? 'teal' : undefined}
                css={cardStyle}
              >
                  <CardContent>
                      <CardHeader>
                          <Checkbox
                            toggle
                            label={t('reports.performance_configuration.options.best_sellers.display')}
                            checked={options.bestsellers.display}
                            onChange={() => {
                                onChange({
                                    ...options,
                                    bestsellers: {
                                        ...options.bestsellers,
                                        display: !options.bestsellers.display,
                                    },
                                })
                            }}
                          />
                      </CardHeader>
                  </CardContent>

                  <CardContent>
                      {t('reports.performance_configuration.options.best_sellers.details', {count: options.bestsellers.count})}
                  </CardContent>
              </Card>

              <Card
                color={options.counterPerformance.display ? 'teal' : undefined}
                css={cardStyle}
              >
                  <CardContent>
                      <CardHeader>
                          <Checkbox
                            toggle
                            label={t('reports.performance_configuration.options.counter_performance.display')}
                            checked={options.counterPerformance.display}
                            onChange={() => {
                                onChange({
                                    ...options,
                                    counterPerformance: {
                                        ...options.counterPerformance,
                                        display: !options.counterPerformance.display,
                                    },
                                })
                            }}
                          />
                      </CardHeader>
                  </CardContent>

                  <CardContent>
                      {t('reports.performance_configuration.options.counter_performance.details')}
                      <List
                        css={css`
                            &.ui.list {
                                margin-top: 0;
                            }
                        `}
                        bulleted
                      >
                          <ListItem>{t('reports.performance_configuration.options.counter_performance.detail_item_1')}</ListItem>
                          <ListItem>{t('reports.performance_configuration.options.counter_performance.detail_item_2')}</ListItem>
                          <ListItem>{t('reports.performance_configuration.options.counter_performance.detail_item_3')}</ListItem>
                      </List>
                  </CardContent>
              </Card>
          </CardGroup>
      </div>
    )
}

export { Options }
