import {call, METHODS} from '../http-client';

const ROUTE_PREFIX = 'devices';

export const registerDevice = async (token, manual = false, soundMode = 'LONG', currentDeviceId = undefined) => {
    return await call(METHODS.PUT, ROUTE_PREFIX, `register`, {
        token,
        manual,
        currentDeviceId,
        soundMode
    });
}

export const unregisterDevice = async (id) => {
    return call(METHODS.DELETE, ROUTE_PREFIX, `${id}`)
}

export const getDevice = async (id) => {
    return call(METHODS.GET, ROUTE_PREFIX, `${id}`)
}

export const patchDevice = async (id, patch) => {
    return call(METHODS.PATCH, ROUTE_PREFIX, `${id}`, patch)
}
