/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { memo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Divider, Grid, Header, Icon, Image, Label, List, Message, Segment, Statistic } from 'semantic-ui-react'
import { getFulfillmentAlternatives } from '../../../../services/Order/Order.service'
import { UltyMoney } from '@ulty/ulty-ui'
import useUser from '../../../Shared/UserProvider/useUser'
import ItemSelector from '../../../Items/ItemElements/ItemSelector'
import { notify } from 'react-notify-toast'
import Zoom from 'react-medium-image-zoom'
import { useDeviceMediaQuery } from '../../../Shared/UseDeviceMediaQuery/useDeviceMediaQuery'
import styled from '@emotion/styled'


const borderTeal = css`
    border: 1px solid #00b5ad !important;
`;


const ItemReplacementLabel = memo(({reason, platform, ...props}) => {
    const [t] = useTranslation()

    return (
      (reason === 'customer_request' ?
        <Label size="tiny" color="orange" {...props}>{t('order.substitution.reasons.customer_request')}</Label>
        : reason === 'platform_recommandation' ?
          <Label size="tiny" color="teal" {...props}>{t('order.substitution.reasons.platform_recommandation', {platform})}</Label>
          : reason === 'similar_product' ?
            <Label  size="tiny" color="teal" {...props}>{t('order.substitution.reasons.similar_product')}</Label>
            : reason === 'your_search' ?
                <Label  size="tiny" color="teal" {...props}>{t('order.substitution.reasons.your_search')}</Label>
            : null)
    )
});

const Container = styled.div`
max-width: 500px;
margin: 0 auto 0 auto;


div.visible.menu.transition {
    max-height: 50vh !important;
}
`;

const ItemDisplay = ({item, withBarcode = false,label = null}) => {
    const [t] = useTranslation();
    const {user} = useUser();
    const {isMobile} = useDeviceMediaQuery();

    const currency = user.getCompanyCurrency();
    const locale = user.getCompanyLocale();

    const image = item?.image || item?.images?.[0]?.url;

    return  <Grid>
        {label}
        <Grid.Row centered css={css`padding-bottom: 0.5em!important`}>
        <strong>{item.object?.name||item.name}</strong>
        </Grid.Row>
        <Grid.Row verticalAlign='middle'  css={css`padding-top: 0 !important;`}>
            <Grid.Column  mobile={8} tablet={8} computer={8} css={css`padding-bottom: 0;`}>
                <Zoom>
                    <Image src={image} css={css` max-height: 100px; 
                                margin: auto; `}/>
        </Zoom>
    </Grid.Column>
    <Grid.Column textAlign='center' computer={8} tablet={8} mobile={8} css={css`
      display: flex !important;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding-bottom: 0;
      label {
        font-weight: bold;
      }
    `}>
       
        <div css={css`
                  display: flex;
                  flex-direction: row;
                  width: 100%;
                  align-items: center;
                  max-height: 95px;
                  .statistic { margin: 0; flex: 1; }
                `}>
                    <Statistic size='mini'>
                        <Statistic.Label  css={css`font-size: .60em !important;`}>{t('order.pickup.wrong_item_details.price')}</Statistic.Label>
                        <Statistic.Value css={css`font-size: 1.8rem !important`}>
                            <UltyMoney
                                readonly
                                amount={item.inventory?.sellPrice || item.unitPrice || item.basePrice}
                                currencyCode={currency}
                                locale={locale}
                            />
                        </Statistic.Value>
                    </Statistic>
        </div>
        {withBarcode && (
            <div css={css`
                  display: flex;
                  flex-direction: row;
                  width: 80%;
                  align-items: center;
                  justify-content: space-around;
                  max-height: 95px;

                  .statistic { margin: 0; flex: 1; }
                `}>
                    { item.object?.barcode && (
                        <Label basic size="mini"   css={css`text-align: center; border: 0px!important;`}>
                           <Icon name="barcode" color="grey"/>{item.object?.barcode}
                        </Label>
                    )}
            </div>
        )}
    </Grid.Column>
    </Grid.Row>
    </Grid>
}


const OrderItemReplacement = ({
                                  order,
                                  orderItem,
                                  addOrderItemToFulfillmentIssues,
                                  initialPickingMethod,
                                  afterSubmit,
                                  preloadedSearch = undefined,
                              }) => {
    const [t] = useTranslation()
    const [quantity, setQuantity] = useState(orderItem.quantity)
    const [loading, setLoading] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [substitutionId, setSubstitutionId] = useState(null)
    const [pickingMethod, setPickingMethod] = useState(initialPickingMethod)
    const {user} = useUser()
    const [errorInfo, setErrorInfo] = useState(null)
    const retrieveReplacement = useCallback(async ({search, page, size, wasScanned = false}, cancellationSource) => {
        setLoading(true)
        try {
            const items = await getFulfillmentAlternatives({
                order,
                orderItem,
                needle: search,
                quantity: orderItem.quantity,
                page,
                size,
            }, cancellationSource)

            setErrorInfo(null)
            return {items}
        } catch (error) {
            const knownErrorMessages = [
                'NO_ITEM_FOUND_IN_THIS_PRICE_RANGE',
                'NO_ITEM_FOUND_WITHIN_PUBLISHED_MENU',
                'NO_ITEM_FOUND',
                'NO_ITEM_FOUND_WITH_AVAILABLE_INVENTORY',
            ]

            if (knownErrorMessages.includes(error.message) && page === 1) {
                const context =
                  (pickingMethod === 'SCAN' || wasScanned) ? 'scan'
                    : !search ? 'suggestion'
                      : 'search'
                const code = error.message
                setErrorInfo({context, code})
            }

            if (!knownErrorMessages.includes(error.message)) {
                notify.show(t('global.anErrorOccurred'), 'error')
            }

            return {items: []}
        } finally {
            setLoading(false)
        }
    }, [order, orderItem, t, pickingMethod])


    const currency = user.getCompanyCurrency()
    const locale = user.getCompanyLocale()

    const renderItem = useCallback((i) => ({
        content: (
          <Header key={`item-${i.id}`} as="h2" css={css`
              display: flex;
          `}>
              <Image src={i.image} loading="lazy"/>
              <div css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: space-between;
                  width: 100%;
                  padding-left: 1em;
                  font-size: 1rem;
                  line-height: 1.2em;
              `}>
                  <Header.Content>
                      {i.object.name}
                  </Header.Content>
                  <Header.Subheader css={css`
                      width: 100%;
                      max-width: 180px;
                  `}>
                      <div>
                          <Icon name="barcode" color="grey"/>{i.object?.barcode}
                      </div>
                      <div css={css`
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                      `}>
                          <UltyMoney
                            readonly
                            amount={i.inventory.sellPrice || i.basePrice}
                            currencyCode={currency}
                            locale={locale}
                          />
                          {i.inventory?.quantity && (
                            <span>
                    <Icon name="shop"/> {i.inventory?.quantity || 0}
                </span>
                          )}
                      </div>
                  </Header.Subheader>
              </div>
          </Header>
        ),
    }), [currency, locale])


    const removeFromCart = () => {
        addOrderItemToFulfillmentIssues(orderItem, 0)
        afterSubmit()
    }

    const apply = () => {
        addOrderItemToFulfillmentIssues(orderItem, 0, pickingMethod, {
            parent: orderItem.id,
            itemId: selectedItem.id,
            name: selectedItem.object.name,
            unitPrice: selectedItem.inventory.sellPrice || selectedItem.basePrice,
            quantity,
            substitutionId,
            ...(pickingMethod === 'SCAN' ? {scanned: true} : {}),
        })

        afterSubmit()
    }

    const onItemChange = useCallback((item, pickingMethod) => {
        if (!item) {
            setSubstitutionId(null)
            setQuantity(orderItem.quantity)
            return
        }
        setSelectedItem(item)
        setSubstitutionId(item.substitutionId)
        setQuantity(orderItem.quantity)
        setPickingMethod(pickingMethod ?? initialPickingMethod)
    }, [orderItem.quantity, initialPickingMethod])

    return (
      <Container>
          <Segment compact css={css`text-align: center; align-items: center; justify-content: center; width: 100%;`}>
                <ItemDisplay item={orderItem} withBarcode label={<Label size="large" attached="top" color="grey">{t('order.substitution.initial_item')}</Label>} />
            <List>
                <List.Item>
                {( <Button
                loading={loading}
                color="red"
                basic
                onClick={removeFromCart}>
                  {t('order.substitution.remove_from_cart')}
              </Button>)}
                </List.Item>
            </List>
          </Segment>

          {selectedItem && (
            <Segment  compact css={css`${borderTeal} text-align: center; align-items: center; justify-content: center; margin-top: 10px; width: 100%;`}>
                <ItemDisplay item={selectedItem} withBarcode label={<ItemReplacementLabel reason={selectedItem.reason} platform={order.platform.name} attached="top" size="large" />}/>
                <List>
                    <List.Item>
                                
                        
                        <Button
                            disabled={selectedItem === null}
                            loading={loading}
                            color="teal"
                            onClick={apply}>
                                <Trans
                                    i18nKey="order.substitution.replace_in_cart"
                                    components={{
                                        bold: <strong css={css`font-size:larger`} />,
                                    }}
                                    count={quantity}
                               />
                        </Button>
                    </List.Item>
                </List>
            </Segment>
          )}
          
          {errorInfo && (
            <Message>
                <span>{t(`order.substitution.not_found.${errorInfo.context}.${errorInfo.code}`, {platform: order.platform.name})}</span>
                <br/>
                <span>{t(`order.substitution.not_found.${errorInfo.context}.call_to_action`, {platform: order.platform.name})}</span>
            </Message>
          )}

          
        {<>
              <div css={css`
                  font-size: .92857143em;
                  font-weight: bold;
                  margin-top: 10px;
              `}>{t('order.substitution.replacement_item')}</div>
              <div css={css`margin-top: 10px; margin-bottom: 10px`}>
                  <ItemSelector
                    preloadedSearch={preloadedSearch}
                    selectFirstAtLoad
                    placeholder={t('order.substitution.choose_item')}
                    clearable={false}
                    onItemChange={onItemChange}
                    searchFunction={retrieveReplacement}
                    itemRenderer={renderItem}
                  >
                  </ItemSelector>
              </div>
          </>}

          <Divider/>

          <div css={css`
              margin-top: 20px;
              text-align: right;
          `}>
                <Button
                  loading={loading}
                  onClick={afterSubmit}>
                    {t('global.cancel')}
                </Button>
             
          </div>
      </Container>
    )
}

OrderItemReplacement.propTypes = {
    order: PropTypes.object.isRequired,
    orderItem: PropTypes.object.isRequired,
    addOrderItemToFulfillmentIssues: PropTypes.func.isRequired,
    initialPickingMethod: PropTypes.oneOf(['MANUAL', 'SCAN']),
    afterSubmit: PropTypes.func.isRequired,
    preloadedSearch: PropTypes.string,
}

export default OrderItemReplacement
