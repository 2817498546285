/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useEffect } from 'react'
import {Toaster} from 'react-hot-toast';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'

import {
  Container, Divider, Flag
} from 'semantic-ui-react';

import NavBar from './NavBar'
import Home from './Home/Home'
import Products from './Products'
import Categories from './Categories';
import Admin from './Admin'
import Orders from './Orders'
import Extras from './Extra';
import Menus from './Menu';

import EnsureLogin from './Auth/EnsureLogin'

import NavBreadcrumb from './NavBar/Breadcrumb'

import ProductStore from './Products/ProductStore'
import OrderStore from './Orders/OrderStore'
import { SocketClientProvider } from '../services/socket'

import Notification from './Notification'
import useUser from './Shared/UserProvider/useUser';
import PrivateRoute from './Shared/PrivateRoute/PrivateRoute';

import { useTranslation } from 'react-i18next';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import ItemImagePlatformsAssociation from './ItemImagePlatformsAssociation/ItemImagePlatformsAssociation';
import {BreadcrumbContextProvider} from './NavBar/BreadcrumbContext';
import { AUTH_TOKEN, MOBILE_THRESHOLD } from '../constants';
import PlatformError from './PlatformError';
import { isProviderAPos } from '../services/Provider/Provider.service';
import { PrinterInfoProvider } from '../printing/services/PrinterInfo/PrinterInfo.provider';
import { retrieveLastSync, retriggerRegistration } from '../devices/device.provider';
import { BusyModeMessage } from './Notification/BusyModeMessage'
import { useDeviceMediaQuery } from './Shared/UseDeviceMediaQuery/useDeviceMediaQuery';

const AndroidRouter = () => {
  const {user} = useUser();
  const history = useHistory();

  const updateToken = async () => {
    try {
      if (isProviderAPos(user?.provider) && user?.providers?.length === 1) {
        const lastSynced = retrieveLastSync();

        if (!lastSynced || ((new Date()).getTime() - parseInt(lastSynced) >= (24*60*60*1000))) {
          if(!window.Android) return;

          await retriggerRegistration();
        }
      }
    } catch(e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (window.Android) {
      updateToken();
    }

    const handler = () => {
      history.push('/orders/current');
    }
    window.addEventListener("AndroidNotification", handler, false);

    return () => {
      window.removeEventListener('AndroidNotification', handler);
    };
  }, []);

  useEffect(() => {
    if(window.Android && window.Android.setCurrentUser){
      const token = localStorage.getItem(AUTH_TOKEN);
      window.Android.setCurrentUser(token);
    }
  },[user])

  return null;
}

const Index = ({ history, location }) => {
  const {landingRoute, user} = useUser();
  const {isMobile} = useDeviceMediaQuery();

  const {i18n} = useTranslation()

  const setLanguage = (lng) => {
    localStorage.setItem('ulty-lng', lng);
    window.location.reload()
  }

  return (
    <>
      <OrderStore.Provider>
        <EnsureLogin location={location} history={history}>
          <SocketClientProvider>
            <PrinterInfoProvider>

            <Notification>
              <BreadcrumbContextProvider>
                <AndroidRouter />
                <PlatformError />
                <NavBar location={location} history={history} />
                <Container style={{ marginTop: isMobile ? '4em' : '6em', flex: 1 }}>
                  <ProductStore.Provider>
                    <NavBreadcrumb history={history} location={location} />
                    {!isMobile && (
                      <Divider />
                    )}
                    <BusyModeMessage />
                    <Switch>
                      <PrivateRoute path="/products">
                        <Products />
                      </PrivateRoute>
                      <Route path="/extras" component={Extras} />
                      <Route path="/menus" component={Menus} />
                      <PrivateRoute path="/categories">
                        <Categories />
                      </PrivateRoute>
                      <Route path="/items/:itemId/platforms" component={ItemImagePlatformsAssociation} />
                      <Route path="/orders" component={Orders} />
                      <Route path="/settings" component={Admin} />
                      <PrivateRoute path="/analytics" staticResource='metrics'>
                        <Home key={user.provider?.id} />
                      </PrivateRoute>
                      <Redirect to={landingRoute()} />
                    </Switch>
                  </ProductStore.Provider>
                </Container>
                <Divider />
                <div css={css`
                  display: flex;
                  align-items: center;
                  width: 100%;
                  justify-content: center;
                  padding-bottom: 10px;
                `}>
                  <div css={css`
                    display: flex;
                    justify-content: center;

                    .flag-elmt {
                      margin-left: 10px;
                      margin-right: 10px;
                      padding-bottom: 2px;
                      cursor: pointer;
                    }

                    .flag-elmt.selected {
                      border-bottom: 2px solid #00b5ad;
                    }

                    .flag {
                      margin: 0 !important;
                      cursor: pointer;
                    }
                  `}>
                    <div className={`flag-elmt ${i18n.language.indexOf('fr') === 0 ? 'selected' : ''}`}>
                      <Flag
                        name="fr"
                        onClick={() => setLanguage('fr-FR')}
                      >
                      </Flag>
                    </div>
                        /
                        <div className={`flag-elmt ${i18n.language.indexOf('en') === 0 ? 'selected' : ''}`}>
                      <Flag
                        name="uk"
                        className="selected"
                        onClick={() => setLanguage('en-US')}
                      >
                      </Flag>
                    </div>
                  </div>
                </div>
              </BreadcrumbContextProvider>
            </Notification>
            </PrinterInfoProvider>
          </SocketClientProvider>
        </EnsureLogin>
      </OrderStore.Provider>
      <Toaster toastOptions={{
        className: 'toaster',
        position: isMobile ? "top-center" : "top-right"
      }} />
    </>
  )
}

export default Index
