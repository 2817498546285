/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery'
import { MOBILE_THRESHOLD } from '../../constants'
import useUser from '../Shared/UserProvider/useUser'
import OrderStore from '../Orders/OrderStore'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getMerchantStatus, setBusyMode } from '../../services/PointOfSale/PointOfSale.service'
import { notify } from 'react-notify-toast'
import { isProviderACompany } from '../../services/Provider/Provider.service'
import { Button, Icon, Message, MessageContent, MessageHeader } from 'semantic-ui-react'
import humanizeDuration from '../../helpers/humanizeDuration'
import { TIME } from '../../services/date.service'
import i18n from 'i18next'
import { useEffect } from 'react'

const BusyModeMessage = () => {
    const [t] = useTranslation()
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`)
    const {user} = useUser()
    const orderStore = OrderStore.useContainer()
    const updateBusyModeMutation = useMutation({
        mutationFn: () => setBusyMode({status: orderStore.pausedAt ? 'IDLE' : 'BUSY'}),
        onSuccess: (data) => {
            orderStore.setPausedAt(data.pausedAt)
        },
        onError: () => {
            notify.show(t('global.anErrorOccurred'), 'error')
        },
    })

    const pauseDuration = orderStore.pausedAt ? new Date() - new Date(orderStore.pausedAt) : 0
    const lessThan1Minute = pauseDuration < TIME.MINUTE_IN_MS
    const lessThan48Hours = pauseDuration < 48 * TIME.HOUR_IN_MS

    const { data: posStatus, isSuccess } = useQuery({
        queryKey: ['pointofsales', 'busy'],
        queryFn: getMerchantStatus
    })

    useEffect(() => {
        if (isSuccess) {
            orderStore.setPausedAt(posStatus.pausedAt)
        }
    }, [isSuccess, posStatus])


    if (isProviderACompany(user?.provider)) {
        return null
    }

    const getHeaderMessage = () => {
        if (lessThan1Minute) {
            return t('navbar.busy_mode.busy_mode')
        }

        if (lessThan48Hours) {
            return t('navbar.busy_mode.busy_mode_since_duration', {
                duration: humanizeDuration(new Date(), orderStore.pausedAt, {
                    round: true,
                    units: ['h', 'm'],
                    largest: 2,
                }),
            })
        }

        return t('navbar.busy_mode.busy_mode_since_date', {
            date: new Intl.DateTimeFormat(i18n.language, {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
            }).format(new Date(orderStore.pausedAt)),
        })
    }

    return (
      <div css={css`
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: ${isMobile ? '10px' : 0};
      `}>
          {orderStore.pausedAt && (
            <div>
                <Message
                  icon
                  attached
                  color="orange"
                  size="small"
                  compact
                >
                    <Icon name="warning sign"/>
                    <MessageContent>
                        <MessageHeader>
                            {getHeaderMessage()}
                        </MessageHeader>
                        <span>{t('navbar.busy_mode.closed_on')}</span>
                    </MessageContent>
                </Message>
                <Message
                  attached="bottom"
                  color="orange"
                  size="huge"
                  css={css`
                      &.ui.bottom.attached.message {
                          padding-top: 0;
                          padding-left: 1.5rem;
                          padding-right: 1.5rem;
                          box-shadow: -1px -1px 0 0 #f2711c inset,
                          1px -1px 0 0 #f2711c inset,
                          0 -1px 0 0 #f2711c inset;
                      }
                  `}
                >
                    <Button
                      size="tiny"
                      fluid
                      color="orange"
                      onClick={updateBusyModeMutation.mutate}
                      disabled={updateBusyModeMutation.isPending}
                      loading={updateBusyModeMutation.isPending}>
                        {t('navbar.busy_mode.stop_busy_mode')}
                    </Button>
                </Message>
            </div>
          )}
      </div>
    )
}

export { BusyModeMessage }
