/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect, useState, useContext } from 'react'
import {
    exportCategorySet,
    getCategorySets,
    isCategoryProvidedByUserProvider,
} from '../../services/Category/Category.service'
import { Tab, Form, Button, Icon, Card, Label, Divider, Grid } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { UltyModalWrapperContext } from '../Shared/UltyModalWrapper/UltyModalWrapperContext'
import CategoryAddSet from './CategoryAddSet'
import CategoryRemoveSet from './CategoryRemoveSet'
import { useTranslation } from 'react-i18next'
import CategoryLinks from './CategoryLinks'
import useUser from '../Shared/UserProvider/useUser'
import { notify } from 'react-notify-toast'
import { saveFile } from '../../services/File/File.service'
import useBreadcrumb from '../NavBar/useBreadcrumb'
import { isProviderAPos } from '../../services/Provider/Provider.service'
import { useMutation } from '@tanstack/react-query'
import { sortDesc } from '../../helpers/array'

const CategorySet = () => {
    const {user, canCreateCategories, canExtendCategories} = useUser()
    const [t] = useTranslation()
    const [loading, setLoading] = useState(false)
    const [sets, setSets] = useState([])
    const {setPaths} = useBreadcrumb()

    useEffect(() => {
        setPaths([{
            text: t('breadcrumb.home'),
            link: true,
            path: '/',
        }, {
            text: t('breadcrumb.categories'),
            link: false,
        }])
    }, [])

    const {handleUltyModalWrapper} = useContext(UltyModalWrapperContext)
    const defineAddModal = () => {
        const modalSettings = {
            title: t('category_set.new_set'),
            component: <CategoryAddSet
              afterSubmit={() => {
                  loadCategorySets()
                  handleUltyModalWrapper(false, null)
              }}
            />,
        }
        handleUltyModalWrapper(true, modalSettings)
    }
    const defineRemoveModal = (s) => {
        const modalSettings = {
            title: t('category_set.delete_set'),
            component: <CategoryRemoveSet
              set={s}
              afterSubmit={() => {
                  loadCategorySets()
                  handleUltyModalWrapper(false, null)
              }}
            />,
        }
        handleUltyModalWrapper(true, modalSettings)
    }

    const loadCategorySets = async () => {
        setLoading(true)

        const sets = await getCategorySets();
        const withHavingLinksFirst = sortDesc(sets, s => s.hasLink);
        setSets(withHavingLinksFirst)

        setLoading(false)
    }

    useEffect(() => {
        loadCategorySets()
    }, [])

    const exportCategorySetMutation = useMutation({
        mutationFn: async ({ set }) => {
            const { data } = await exportCategorySet(set.id)
            return saveFile(t('category_set.export_set_file_name'), data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        },
        onError: (error) => {
            console.log(error)
            notify.show(t('global.anErrorOccurred'), 'error')
        },
    })

    return (
      <Form>
          <Tab menu={{secondary: true, pointing: true}} panes={[
              {
                  menuItem: t('category_set.manage_menu'),
                  render: () => (
                    <Tab.Pane attached={false} loading={loading}>
                        {canCreateCategories() && (
                          <>
                              <Form.Group widths="equal" css={css`margin-bottom: 0 !important;`}>
                                  <Form.Field inline css={css`text-align: right;`}>
                                      <Button icon color="teal" onClick={() => defineAddModal()}>
                                          <Icon name="copy"/>
                                          {t('category_set.new_set')}
                                      </Button>
                                  </Form.Field>
                              </Form.Group>
                              <Divider/>
                          </>
                        )}
                        <Card.Group css={css`
                            justify-content: space-around;
                        `}>
                            {sets.map(s => (
                              <Card key={s.id} css={css` margin: 5px; `} >
                                  <Card.Content>
                                      <div css={css`
                                          margin-bottom: 10px;
                                      `}>
                                          {/* {s.hasLink && <Label size="tiny" corner="left"  /> } */}

                                          {s.provider.type === 'COMPANY' ? (
                                            <Label
                                                size="mini" ribbon="right" color="blue"
                                                >
                                                {t('global.company')}
                                            </Label>
                                          ) : (
                                            <Label size="mini" ribbon="right" color="green"
                                            >
                                                {t('global.merchant')}
                                            </Label>
                                          )}
                                      </div>
                                      <Card.Header as={Link} to={`/categories/${s.id}`} css={css`
                                          margin-top: 10px;
                                      `}>{s.name}</Card.Header>
                                      <Card.Meta>{(new Date(s.createdAt)).toLocaleDateString()}</Card.Meta>
                                      <Card.Description>
                                          <Grid>
                                              <Grid.Column width={16} textAlign="left" css={css`label {
                                                  font-weight: bold;
                                              }`}>
                                                  <div>
                                                      <label>{t('category_set.slot_number')}: </label><span>{s.slots.length}</span>
                                                  </div>
                                                  <div>
                                                      <label>{t('category_set.item_number')}: </label><span>{s.itemsCount || 'N/A'}</span>
                                                  </div>
                                              </Grid.Column>
                                          </Grid>
                                      </Card.Description>
                                  </Card.Content>
                                  <Card.Content extra css={css`text-align: right;`}>
                                      <div css={css`
                                          display: flex;
                                          justify-content: flex-end;
                                      `}>
                                          {isCategoryProvidedByUserProvider(s, user) &&
                                            <Button basic size="mini" color="red"
                                                    onClick={() => defineRemoveModal(s)}>{t('global.delete')}</Button>
                                          }
                                          <Button basic size="mini" color="teal" as={Link}
                                                  to={`/categories/${s.id}`}>{((isProviderAPos(user.provider) && !canExtendCategories()) || !isCategoryProvidedByUserProvider(s, user)) ? t('global.see') : t('global.edit')}</Button>
                                          {isCategoryProvidedByUserProvider(s, user) &&
                                            <Button
                                              basic
                                              size="mini"
                                              color="teal"
                                              loading={exportCategorySetMutation.isPending}
                                              onClick={() => exportCategorySetMutation.mutate({ set: s })}
                                            >
                                                <Icon name={'file excel'}/>
                                                {t('category_set.export')}
                                            </Button>
                                          }
                                      </div>
                                  </Card.Content>
                              </Card>
                            ))}
                        </Card.Group>
                    </Tab.Pane>
                  ),
              },
              {
                  menuItem: t('category_set.manage_link'),
                  render: () => (
                    <Tab.Pane attached={false} loading={loading}>
                        <div css={css`margin-top: 10px;`}>
                            <CategoryLinks categorySets={sets}/>
                        </div>
                    </Tab.Pane>
                  ),
              },
          ]}/>

      </Form>
    )
}

export default CategorySet
