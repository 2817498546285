/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useState, useEffect, useCallback, useContext, useRef, useMemo, useId } from 'react'
import { updateOrderStatus } from '../../../helpers/api'
import {
    Segment,
    Grid,
    Image,
    Icon,
    Placeholder,
    Message,
    Button, Loader, Ref, List, Label,
} from 'semantic-ui-react'
import { notify } from 'react-notify-toast'
import formatDate from '../../../helpers/formatDate'
import { UltyModalWrapperContext } from '../../Shared/UltyModalWrapper/UltyModalWrapperContext'
import {
    acceptOrder, addEventToOrder,
    denyOrder,
    getOrder,
    postPickingProof, setFulfillmentIssues,
} from '../../../services/Order/Order.service'
import { useTranslation } from 'react-i18next'
import OrderDenyReasonMessage from '../OrderDenyReason/OrderDenyReasonMessage'
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery'
import { OrderDetailContext } from './OrderDetailContext'
import { useHistory, useParams } from 'react-router-dom'
import OrderEvents from '../OrderEvents/OrderEvents'
import RequestCourier from './RequestCouriers'
import UltyPopup from '../../Shared/UltyPopup/UltyPopup'
import CancelOrder from './CancelOrder'
import useBreadcrumb from '../../NavBar/useBreadcrumb'
import useUser from '../../Shared/UserProvider/useUser'
import { MOBILE_THRESHOLD } from '../../../constants'
import { OrderItemsList, orderItemsListDisplayMode } from './OrderItemList/OrderItemsList'
import OrderFlowActions from './OrderFlowActions/OrderFlowActions'
import OrderDelivery from './OrderDelivery'
import OrderPOSDelivery from './OrderPOSDelivery'
import { isProviderAPos } from '../../../services/Provider/Provider.service'
import PlatformModule from '../../../services/Platform/PlatformModule'
import { PrintOrderButton } from '../../../printing/components/PrintButton/PrintOrderButton'
import { getClientName } from '../../../helpers/order'
import { ScanningInformationLabel, useScanning } from '../../../scanning/scanning.service'
import { usePrevious } from '../../../helpers/usePrevious'
import { useCamera } from '../../../camera/useCamera'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AskToAddPickingProof } from '../PickingProof/AskToAddPickingProof'
import { NavigateToPickingProofsGalleryButton } from '../PickingProof/NavigateToPickingProofsGalleryButton'
import { OrderStatusBar } from './OrderStatusBar'

const PHONES = {
    'ubereats': '0805 084 286',
    'deliveroo': '09 77 55 03 31',
    'justeat': '01 86 65 46 15',
}

const PHONES_COUNTRIES = {
    'FR': PHONES,
    'BE': {
        'ubereats': '+32 2 808 66 28',
        'deliveroo': '+32 2 895 40 06 (FR) / +32 2 895 40 07 (NL)',
        'justeat': '',
    },
}

const MAX_PICKING_PROOFS = 5

const getPhones = (countryCode) => {
    return PHONES_COUNTRIES[countryCode] ?? PHONES
}

function orderStatusChangedDuringModification(isModifying, currentStatus, nextStatus) {
    return isModifying && currentStatus !== nextStatus
}

const OrderDetail = () => {
    const [t] = useTranslation()
    const history = useHistory()
    const params = useParams()
    const id = +params.id
    const {setPaths} = useBreadcrumb()
    const [isDenying, setIsDenying] = useState(false)
    const [isDenyAcceptLoading, setIsDenyAcceptLoading] = useState(false)
    const {user} = useUser()
    const productsRef = useRef()
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`)
    const [displayMode, setDisplayMode] = useState(isMobile ? orderItemsListDisplayMode.PRODUCTS : orderItemsListDisplayMode.LINES)
    const [orderFulfillmentIssues, setOrderFulfillmentIssues] = useState([])
    const queryClient = useQueryClient();
    const wasScanningDetected = useRef(false)
    const orderStatus = queryClient.getQueryData(['orders', id])?.status

    function getOrderUpdateMessage(o) {
        let message = `${t('order.detail.updated_by_other_device')}`
        if (!o.isModifiable && isModifying) {
            message += ` ${t('order.detail.order_is_not_modifiable_anymore')}`
        } else if (orderStatusChangedDuringModification(isModifying, orderStatus, o.status)) {
            message += ` ${t('order.detail.updates_have_been_saved')}`
        }
        return message
    }

    const { data: order, isLoading } = useQuery({
        queryFn: async () => {
            const o = await getOrder(id);

            const previousOrderStatus = queryClient.getQueryData(['orders', id])
            if (previousOrderStatus && previousOrderStatus.status !== o.status) {
                const message = getOrderUpdateMessage(o)
                notify.show(message, 'success')
            }

            if (!o.isModifiable) {
                if (isModifying) {
                    notify.show(`${t('order.detail.order_is_not_modifiable_anymore')}`, 'success')
                }

                setIsModifying(false)
                setOrderFulfillmentIssues([])
            } else if (orderStatusChangedDuringModification(isModifying, orderStatus, o.status)) {
                await saveFulfillmentIssues()
            }

            if (window.Android && window.Android.acknowledgeOrder) {
                window.Android.acknowledgeOrder(o.id);
            }
            wasScanningDetected.current = false
            return o;
        },
        refetchInterval: 10000,
        queryKey: ['orders', id],
        enabled: !orderStatus || ['RECEIVED', 'ACCEPTED', 'PREPARING', 'READY'].includes(orderStatus),
        select: (order) => {
            order.orderItems.sort((i1, i2) => {
                if (!i1.categoryName && !i2.categoryName) {
                    return i1.id - i2.id
                } else if (!i1.categoryName) {
                    return -1
                } else if (!i2.categoryName) {
                    return +1
                } else {
                    return i1.categoryName.localeCompare(i2.categoryName)
                }
            })

            return order
        },
    })

    const changeOrderStatusMutation = useMutation({
        mutationFn: async ({status}) => {
            if (orderStatusChangedDuringModification(isModifying, orderStatus, status) && order.isModifiable) {
                await saveFulfillmentIssues()
            }
            return await updateOrderStatus(id, status)
        },
        onSuccess: async (data, { status }) => {
            if (data.error && data.error === 'CANCELLED') {
                notify.show(t('order.error_cancelled'), 'error')
            }

            queryClient.setQueryData(['orders', id], (prevData) => ({
                ...prevData,
                status
            }))
            await queryClient.invalidateQueries({ queryKey: ['orders', id] })
        },
    })

    const {isModifying, setIsModifying,isInReplacement} = useContext(OrderDetailContext)
    const wasModifying = usePrevious(isModifying)

    const { mutate: addStatusViewedEvent } = useMutation({
        mutationFn: () => addEventToOrder(id, 'STATUS_VIEWED'),
    })

    useEffect(() => {
        if (order?.status === 'RECEIVED') {
            addStatusViewedEvent()
        }
    }, [addStatusViewedEvent, order?.status])

    useEffect(() => {
        if (window.Android && window.Android.stopNotifications) {
            window.Android.stopNotifications()
        }
    }, [])

    useEffect(() => {
        if (!wasModifying && isModifying) {
            if(wasScanningDetected.current && ['RECEIVED','ACCEPTED'].includes(order.status)) {
                changeOrderStatusMutation.mutate({status: 'PREPARING'})
            }
            productsRef?.current?.scrollIntoView({behavior: 'smooth', block: 'start'})
        }
    }, [wasModifying,isModifying,order?.status,changeOrderStatusMutation])

    const onScanDetected = useCallback(() => {
        if (order?.isModifiable && !isInReplacement) {
            wasScanningDetected.current = true
            setDisplayMode(orderItemsListDisplayMode.PRODUCTS)
            setIsModifying(true)
        }
    }, [order?.isModifiable,isInReplacement])
    useScanning(onScanDetected)

    useEffect(() => {
        setPaths([{
            text: t('breadcrumb.home'),
            link: true,
            path: '/',
        }, {
            text: t('breadcrumb.orders'),
            link: true,
            path: `/orders/${isProviderAPos(user.provider) ? 'current' : 'finished'}`,
        }, {
            text: order?.brand?.name || t('global.loading'),
            link: false,
        }, {
            text: order ? `${order.platform.name} - ${order.displayId}` : t('global.loading'),
            link: false,
        }])
    }, [order])

    const shouldDisplayTimer = useMemo(() => {
        return !!order
          && (
            order.status === 'RECEIVED'
            || (
              (order.status === 'ACCEPTED' || order.status === 'PREPARING')
              && order.platform.displayPreparationTimer)
          )
    }, [order])

    const postPickingProofMutation = useMutation({
        mutationFn: ({orderId, pickingProof}) => postPickingProof(orderId, pickingProof),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['orders', id] })
        },
    })

    const cameraId = useId()
    const {takePhoto, isCameraAvailable} = useCamera((image) => {
        postPickingProofMutation.mutate({orderId: order.id, pickingProof: image})
    }, cameraId)

    const switchInDenyMode = () => {
        setIsModifying(false)
        setIsDenying(!isDenying)
    }

    const switchInModificationMode = () => {
        setIsDenying(false)
        setIsModifying(true)
    }

    const onChangeFulfillmentIssues = (fulfillmentIssues) => {
        setOrderFulfillmentIssues(fulfillmentIssues)
    }

    const saveFulfillmentIssues = async () => {
        if (!orderFulfillmentIssues || orderFulfillmentIssues.length === 0) {
            return
        }

        setIsModifying(false)
        const {status} = await setFulfillmentIssues(order, orderFulfillmentIssues);
        if (status === 'RECEIVED') {
            await handleAcceptOrder()
        }
        await queryClient.invalidateQueries({ queryKey: ['orders', id] })
    }

    const {handleUltyModalWrapper} = React.useContext(UltyModalWrapperContext)
    const canTakePickingProof = isCameraAvailable() && (order?.pickingProofs || []).length < MAX_PICKING_PROOFS

    const handleMarkAsReadyOrder = async () => {
        try {
            setIsDenyAcceptLoading(true)
            if (canTakePickingProof) {
                const remainingPickingProofs = MAX_PICKING_PROOFS - order.pickingProofs.length
                const getModalSettings = ({isFirstAsk, remainingPickingProofs}) => {
                    return {
                        closeIcon: undefined,
                        component: <AskToAddPickingProof
                          orderId={order.id}
                          remainingPickingProofs={remainingPickingProofs}
                          isFirstAsk={isFirstAsk}
                          afterSubmit={async (action) => {
                              if (action === 'CLOSE_AND_CONTINUE' || !canTakePickingProof || remainingPickingProofs <= 1) {
                                  handleUltyModalWrapper(false, null)
                                  changeOrderStatusMutation.mutate({status: 'READY'})
                              } else {
                                  handleUltyModalWrapper(true, getModalSettings({isFirstAsk: false, remainingPickingProofs: remainingPickingProofs-1}))
                              }
                          }}/>,
                    }
                }
                handleUltyModalWrapper(true, getModalSettings({isFirstAsk: true, remainingPickingProofs}))
            } else {
                await changeOrderStatusMutation.mutateAsync({status: 'READY'})
            }
        } catch (e) {
            notify.show(`${t('global.anErrorOccurred')}`, 'error')
        } finally {
            setIsDenyAcceptLoading(false)
        }
    }

    const handleAcceptOrder = async (delay) => {
        try {
            setIsDenyAcceptLoading(true);
            const acceptedOrder = await acceptOrder(order, delay)
            queryClient.setQueryData(['orders', id], acceptedOrder)
            await queryClient.invalidateQueries({ queryKey: ['orders', id] })
            setIsDenyAcceptLoading(false);
        } catch (e) {
            console.log(e)
            notify.show(`${t('global.anErrorOccurred')}`, 'error')
        } finally {
            setIsDenyAcceptLoading(false)
        }
    }

    const handleDenyReasonSubmit = async (denyReason) => {
        setIsDenyAcceptLoading(true)
        const deniedOrder = await denyOrder(order, {type: denyReason.type.name, details: denyReason.details})
        queryClient.setQueryData(['orders', id], () => deniedOrder)
        setIsDenying(false)
        setIsDenyAcceptLoading(false)
    }

    const handleUpdateCourier = async (order) => {
        const modalSettings = {
            title: `${t('order.couriers.title')}`,
            component: <RequestCourier order={order} afterSubmit={async () => {
                await queryClient.invalidateQueries({ queryKey: ['orders', id] })
                handleUltyModalWrapper(false)
            }}/>,
        }
        handleUltyModalWrapper(true, modalSettings)
    }

    const handleCancelOrder = async (order) => {
        const modalSettings = {
            title: `${t('order.cancel.header')}`,
            component: <CancelOrder order={order} afterSubmit={async () => {
                await queryClient.invalidateQueries({ queryKey: ['orders', id] })
                handleUltyModalWrapper(false)
            }}/>,
        }
        handleUltyModalWrapper(true, modalSettings)
    }

    const handleShowPickingProofGallery = () => {
        history.push(`/orders/${order.id}/picking-proofs`)
    }

    const handleShowHistory = async () => {
        const modalSettings = {
            component: <OrderEvents orderId={order.id} afterSubmit={async () => {
                handleUltyModalWrapper(false)
            }}/>,
        }
        handleUltyModalWrapper(true, modalSettings)
    }

    const getClientPhone = (client) => {
        try {
            const m = (client || '').match(/^([^()]*) \((.*)\)$/)
            if (m) {
                return m[2]
            }
        } catch {
        }

        return client || 'N/C'
    }

    const isOrderLoading = isLoading || changeOrderStatusMutation.isPending

    if (!order) {
        return <Loader active/>
    }

    const receivedOrderLifetime = order?.receivedOrderLifetime || order?.platform.receivedOrderLifetime || (order?.platform.module === 'ubereats' && 60 * 6)

    return (
      <>
          <Loader active={isOrderLoading}/>
          {order &&
            <>
                {order.status === 'CANCELLED' || order.status === 'EXPIRED' ? (
                  <Message
                    icon="times"
                    header={t(`order.status.${order.status}`)}
                    content={order.status === 'CANCELLED' ? t('order.cancelled') : t('order.expired')}
                    error
                  />
                ) : (
                  <Segment loading={isOrderLoading} basic css={css`
                      ${isMobile && 'padding: 0 !important;'}
                  `}>
                      {shouldDisplayTimer &&
                        <OrderFlowActions
                          order={order}
                          orderLifetime={receivedOrderLifetime}
                          isDenying={isDenying}
                          isDenyAcceptLoading={isDenyAcceptLoading}
                          onDenyOrder={switchInDenyMode}
                          onMarkAsReady={handleMarkAsReadyOrder}
                          onAcceptOrder={handleAcceptOrder}
                          onFulfillmentIssues={switchInModificationMode}
                          onFulfillmentIssuesComplete={saveFulfillmentIssues}
                          onDenyReasonSubmit={handleDenyReasonSubmit}
                        />
                      }

                      {order.status === 'DENIED' &&
                        <OrderDenyReasonMessage order={order}/>
                      }

                      <OrderStatusBar
                        status={order.status}
                        onStatusChange={changeOrderStatusMutation.mutate}
                      />
                  </Segment>
                )}

                <Segment loading={isOrderLoading}>
                    {order?.isModifiable && <ScanningInformationLabel/>}
                    <Ref innerRef={productsRef}>
                        <>
                            <Grid>
                                <Grid.Column width={10}>
                                    <Button.Group>
                                        <Button icon disabled={isModifying}
                                                active={displayMode === orderItemsListDisplayMode.LINES}
                                                onClick={() => setDisplayMode(orderItemsListDisplayMode.LINES)}>
                                            <Icon name="th list"/>
                                        </Button>
                                        <Button icon disabled={isModifying}
                                                active={displayMode === orderItemsListDisplayMode.PRODUCTS}
                                                onClick={() => setDisplayMode(orderItemsListDisplayMode.PRODUCTS)}>
                                            <Icon name="th"/>
                                        </Button>
                                    </Button.Group>
                                </Grid.Column>
                                <Grid.Column width={6} textAlign="right">
                                    <PrintOrderButton order={order}/>
                                    {!isMobile && order.pickingProofs.length > 0 &&
                                      <NavigateToPickingProofsGalleryButton order={order}/>}
                                    {order && order.status && (
                                      <UltyPopup items={[
                                          ...(!['CANCELLED', 'DENIED', 'RECEIVED', 'EXPIRED'].includes(order.status) ? [{
                                              icon: 'times',
                                              color: 'red',
                                              label: t('order.cancel.button'),
                                              onClick: () => handleCancelOrder(order),
                                              active: false,
                                          }] : []),
                                          ...(!['CANCELLED', 'DENIED', 'RECEIVED', 'COMPLETED', 'EXPIRED'].includes(order.status) ? [{
                                              icon: 'shipping fast',
                                              color: 'teal',
                                              label: t('order.couriers.button'),
                                              onClick: () => handleUpdateCourier(order),
                                              active: order.platform.module === 'ubereats',
                                          }, {
                                              icon: 'pencil',
                                              color: 'yellow',
                                              label: t('order.modify'),
                                              onClick: () => {
                                                  setIsModifying(true)
                                              },
                                              active: order.platform.canEditOrder && order.platform.module !== 'deliveroo',
                                          }, {
                                              icon: 'camera',
                                              color: 'teal',
                                              label: t('order.picking_proof.take_picking_proof'),
                                              onClick: () => {
                                                  takePhoto(cameraId)
                                              },
                                              active: canTakePickingProof,
                                          }] : []),
                                          {
                                              icon: 'image outline',
                                              color: 'teal',
                                              label: t('order.picking_proof.download_x_picking_proofs', {count: order.pickingProofs.length}),
                                              onClick: handleShowPickingProofGallery,
                                              active: isMobile && order.pickingProofs.length > 0,
                                          },
                                          {
                                              icon: 'history',
                                              color: 'teal',
                                              label: t('order.history.header'),
                                              onClick: handleShowHistory,
                                              active: true,
                                          },
                                      ]}/>
                                    )}
                                </Grid.Column>
                            </Grid>

                            <Grid>
                                {!isMobile && (
                                  <Grid.Column tablet={4}>
                                      {isOrderLoading ? (
                                        <Placeholder>
                                            <Placeholder.Image square/>
                                        </Placeholder>
                                      ) : (
                                        <Image src={order.platform.logo}/>
                                      )}
                                  </Grid.Column>
                                )}
                                <Grid.Column tablet={12} mobile={16} computer={12} css={css`
                                    display: flex !important;
                                    flex-direction: column;

                                    label {
                                        font-weight: bold;
                                    }
                                `}>
                                    <div css={css`
                                        display: flex;
                                    `}>
                                        {isMobile && (
                                          <div css={css`
                                              display: flex;
                                              flex-direction: column;
                                          `}>
                                              {isOrderLoading ? (
                                                <Placeholder>
                                                    <Placeholder.Image square/>
                                                </Placeholder>
                                              ) : (
                                                <Image size="tiny" src={order.platform.logo}/>
                                              )}
                                              <div css={css`
                                                  font-weight: bold;
                                                  text-align: center;
                                                  font-size: 1.2em;
                                                  padding-top: 5px;
                                              `}>{order.displayId}</div>
                                          </div>
                                        )}
                                        <List relaxed css={css`
                                            flex: 1;
                                            ${isMobile ? 'padding-left: 1em !important;' : ''}
                                            margin-top: 0 !important;

                                            div.header {
                                                color: #4183c4 !important;
                                        `}>
                                            <List.Item>
                                                <List.Icon name="dolly" size="large" verticalAlign="middle"/>
                                                <List.Content>
                                                    <List.Header>{t('order.type')}: {order.orderType ? t(`order.type_${order.orderType}`) : 'N/C'}</List.Header>
                                                </List.Content>
                                            </List.Item>
                                            <List.Item>
                                                <List.Icon name="hourglass half" size="large"
                                                           verticalAlign="middle"/>
                                                <List.Content>
                                                    <List.Header>{t('order.pickup_at')}: {order.pickupAt ? formatDate(order.pickupAt) : 'N/C'}</List.Header>
                                                </List.Content>
                                            </List.Item>
                                            <List.Item>
                                                <List.Icon name="user" size="large" verticalAlign="middle"/>
                                                <List.Content>
                                                    <List.Header>
                                                        {getClientName(order.customer)}
                                                        <span css={css`margin-left: 1rem`}>
                                                                    {order.customerOrderCount === 1 && (
                                                                      <Label color="yellow" size="mini">
                                                                          <Icon name="thumbs up"/>
                                                                          {t(isMobile ? 'order.detail.first_order_mobile' : 'order.detail.first_order')}
                                                                      </Label>
                                                                    )}

                                                            {[PlatformModule.DELIVEROO].includes(order.platform.module) && order.customerOrderCount > 1 && (
                                                              <Label color="green" size="mini">
                                                                  <Icon name="rocket"/>
                                                                  {t(isMobile ? 'order.detail.nth_order_mobile' : 'order.detail.nth_order', {count: order.customerOrderCount})}
                                                              </Label>
                                                            )}
                                                                </span>
                                                    </List.Header>
                                                </List.Content>
                                            </List.Item>
                                            <List.Item>
                                                <List.Icon name="phone" size="large" verticalAlign="middle"/>
                                                <List.Content>
                                                    <List.Header>{getClientPhone(order.customer)}</List.Header>
                                                </List.Content>
                                            </List.Item>
                                        </List>
                                    </div>

                                    {['ubereats', 'deliveroo', 'justeat'].includes(order.platform.module) && (
                                      <Message icon compact>
                                          <Icon name="phone volume"/>
                                          <Message.Content>
                                              <Message.Header css={css`
                                                  text-align: center;
                                              `}>{`${t('order.hotline')} - ${order.platform.name}`}</Message.Header>
                                              <div css={css`
                                                  font-weight: bold;
                                                  text-align: center;
                                                  font-size: 1.14285714em;
                                              `}>{getPhones(user.getCompanyCountry())[order.platform.module]}{order.platform.module === 'deliveroo' && order.platform.storeId ? ` - Restaurant ID : ${order.platform.storeId}` : ''}</div>
                                          </Message.Content>
                                      </Message>
                                    )}
                                    {isModifying && ['justeat'].includes(order.platform.module) && (
                                      <Message icon compact warning>
                                          <Icon name="warning volume"/>
                                          <Message.Content>
                                              <Message.Header css={css`
                                                  text-align: center;
                                              `}>
                                                  {`${t('order.modify_limited_support.title', {platformName: order.platform.name})}`}
                                              </Message.Header>
                                              <div css={css`
                                                  text-align: center;
                                              `}>{t('order.modify_limited_support.message', {platformName: order.platform.name})}</div>
                                          </Message.Content>
                                      </Message>
                                    )}

                                    {order.delivery?.status && (
                                      <OrderDelivery delivery={order.delivery}/>
                                    )}

                                    {order.orderType === 'POS_DELIVERY' && (
                                      <OrderPOSDelivery orderId={order.id}/>
                                    )}

                                    <OrderItemsList
                                      displayMode={displayMode}
                                      order={order}
                                      orderItems={order.orderItems}
                                      isModifying={isModifying}
                                      onChange={onChangeFulfillmentIssues}
                                      onSave={saveFulfillmentIssues}
                                      onCancel={() => setIsModifying(false)}
                                    />

                                    <div css={css`
                                        display: flex;
                                        flex-direction: ${isMobile ? 'column' : 'row'};
                                    `}>
                                        <div css={css` flex: 1;
                                            white-space: pre-wrap;
                                            margin-top: ${isMobile ? '10px' : 0}`}>
                                            <Icon name="sticky note"/>{t('order.notes')}:
                                            <div css={css` padding: 4px; `}>
                                                {(order.notes || '') || 'N/C'}
                                            </div>
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid>
                        </>
                    </Ref>
                </Segment>
            </>
          }
      </>
    )
}

export default OrderDetail
