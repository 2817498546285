/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, {useEffect, useState, useCallback } from 'react';
import {Dropdown, Icon, Tab, Form, Segment, Button, Header, Input} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import { getCategorySlots, getCategorySets, updateCategorySet } from '../../services/Category/Category.service';
import CategoryList from './CategoryList';
import CategorySlot from './CategorySlot';
import CategoryAddSlot from './CategoryAddSlot';
import { UltyModalWrapperContext } from '../Shared/UltyModalWrapper/UltyModalWrapperContext';
import useUser from '../Shared/UserProvider/useUser';
import PropTypes from 'prop-types';
import useModified from './useModified';
import CategoryModifiedModal from './CategoryModifiedModal';
import {Prompt} from 'react-router-dom';
import useBreadcrumb from '../NavBar/useBreadcrumb';
import { isProviderAPos } from '../../services/Provider/Provider.service';
import { CategoryMergeFile } from './CategoryMergeFile';

const HeaderSetEdit = ({ currentSet }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [setName, setSetName] = useState(currentSet?.name);

    useEffect(() => {
        setSetName(currentSet?.name);
    }, [currentSet]);

    const handleSave = async () => {
        setLoading(true);
        await updateCategorySet(currentSet.id, {name:setName});
        setIsEdit(false);
        setLoading(false);
    }

    if (!isEdit) {
        return (
            <div css={css`
                display: flex;
            `}>
                <Header>{setName}</Header>
                <Icon name="pencil" onClick={() => setIsEdit(true)} css={css` cursor: pointer; margin-left: 10px !important; `} />
            </div>
        );
    }

    return (
        <Form.Field>
            <div css={css`
                display: flex;
            `}>
                <Input
                    loading={loading}
                    name="setName"
                    onChange={(evt, { value }) => setSetName(value)}
                    value={setName}
                />
                <div css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 10px;
                `}>
                    <Button icon="check" circular size="mini" onClick={handleSave} loading={loading} disabled={loading} />
                </div>
                <div css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                `}>
                    <Button icon="times" circular size="mini" onClick={() => setIsEdit(false)} loading={loading} disabled={loading} />
                </div>
            </div>
        </Form.Field>
    )
}

HeaderSetEdit.propTypes = {
    currentSet: PropTypes.object.isRequired,
}

const CategorySetList = ({ match: { params: { id } } }) => {
    const {user} = useUser();
    const {setPaths} = useBreadcrumb();
    const [t] = useTranslation();
    const [loading, setLoading] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [slots, setSlots] = useState([]);
    const [currentSet, setCurrentSet] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const { modified, setModified } = useModified();

    useEffect(() => {
        setPaths([{
            text: t('breadcrumb.home'),
            link: true,
            path: '/'
        }, {
            text: t('breadcrumb.categories'),
            link: true,
            path: '/categories'
        }, {
            text: currentSet?.name||t('global.loading'),
            link: true,
            path: `/categories/${id}`
        }, {
            text: t('breadcrumb.edition'),
            link: false,
        }]);
    }, [currentSet]);

    const defineConfirmModal = (callback) => {
        const modalSettings = {
            title: t('categories.confirm_exit'),
            component: <CategoryModifiedModal
                afterSubmit={(result) => {
                    handleUltyModalWrapper(false, null);
                    if (result) {
                        setModified(false);
                        callback();
                    }
                }}
            />
        };
        handleUltyModalWrapper(true, modalSettings);
    };

    const { handleUltyModalWrapper } = React.useContext(UltyModalWrapperContext);
    const defineAddModal = () => {
        const modalSettings = {
            title: t('category_set.new_slot'),
            component: <CategoryAddSlot
                setId={id}
                afterSubmit={(slotId) => {
                    loadCategorySets(slotId);
                    handleUltyModalWrapper(false, null);
                }}
            />
        };

        handleUltyModalWrapper(true, modalSettings);
    };

    const defineMergeFileModal = () => {
        const modalSettings = {
            title: t('category_set.merge_file'),
            component: <CategoryMergeFile
                setId={id}
                afterSubmit={() => {
                    loadCategorySets();
                    handleUltyModalWrapper(false, null);
                }}
            />
        };

        handleUltyModalWrapper(true, modalSettings);
    };

    const loadCategorySets = async (slotId = null) => {
        setLoading(true);

        const slots = await getCategorySlots(id);
        setSlots(slots);

        const sets = await getCategorySets();
        const [currentSet] = sets.filter(s => s.id === id);
        setCurrentSet(currentSet);

        if (slots.length > 0 && slotId) {
            setSelectedSlot(slotId);
        } else if ((selectedSlot === null && slots.length > 0) || !slots.map(s => s.id).includes(selectedSlot)) {
            setSelectedSlot(slots[0].id);
        } else {
            setSelectedSlot(null);
        }
        setActiveIndex(0);

        setLoading(false);
    }

    useEffect(() => {
        loadCategorySets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const getSlot = useCallback(() => {
        const [slot] = slots.filter(s => s.id === selectedSlot);
        return slot;
    }, [selectedSlot, slots]);

    const getPanes = () => {
        const panes = [
            {
                menuItem: t('category_set.slot_categories'),
                render: () => (
                    <Segment>
                        <div css={css`margin-top: 10px;`}>
                            <CategoryList setId={id} slot={getSlot()} />
                        </div>
                    </Segment>
                ),
            },
        ];

        if (slots.length > 1) {
            panes.push({
                menuItem: t('category_set.manage_slot'),
                render: () => (
                    <Segment>
                        <div css={css`margin-top: 10px;`}>
                            <CategorySlot setId={id} slot={getSlot()} nbSlots={slots.length} reload={loadCategorySets} />
                        </div>
                    </Segment>
                ),
            });
        }

        return panes;
    }

    return (
        <Form>
            <Prompt
                when={modified}
                message={t('categories.confirm_continue')}
            />

            { (user.provider.id === currentSet?.provider?.id) ? (
                <HeaderSetEdit currentSet={currentSet} />
            ) : (
                <Header>{currentSet?.name}</Header>
            )}
            <Segment>
                <Form.Group widths='equal'>
                    <Form.Field inline>
                        { slots.length > 1 && (
                            <>
                                <label>{t('category_set.slot_list')}</label>
                                <Dropdown
                                    selection
                                    loading={loading}
                                    value={selectedSlot}
                                    name="slots"
                                    onChange={(evt, { value }) => {
                                        const callback = () => {
                                            setSelectedSlot(value);
                                        };
                                        if (modified) {
                                            defineConfirmModal(callback);
                                        } else {
                                            callback();
                                        }
                                    }}
                                    options={slots.map(s => ({
                                        text: s.name,
                                        value: s.id,
                                        key: s.id
                                    }))}
                                    placeholder={t('categories.slot')}
                                />
                            </>
                        )}
                    </Form.Field>
                    { (isProviderAPos(currentSet?.provider) || user.provider.type === 'COMPANY') && (
                        <Form.Field inline css={css`text-align: right;`}>
                            <Button.Group color="teal" >
                                <Button icon onClick={() => defineAddModal()}>
                                    <Icon name="copy" />
                                    {t('category_set.new_slot')}
                                </Button>
                                {((user.provider.type === 'COMPANY') && <Dropdown
                                    className='button icon'
                                    colo
                                    trigger={<></>}
                                >
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                        key='merge'
                                        value='merge'
                                        icon="file"
                                        text={t('category_set.merge_file')}
                                        onClick={defineMergeFileModal}
                                        />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )}
                            </Button.Group>
                        </Form.Field>
                    )}
                </Form.Group>
                <div css={css` 
                    font-style: italic;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 10px 0;
                    padding-top: 0;
                `}>
                    {t('product_list.num_items', { n: `${getSlot()?.itemsCount||0}` })}
                </div>
            </Segment>
            { selectedSlot && (
                <Tab menu={{ secondary: true, pointing: true }} activeIndex={activeIndex} onTabChange={(evt, { activeIndex }) => setActiveIndex(activeIndex)} panes={getPanes()} />
            )}

        </Form>
    );
}

CategorySetList.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired
        })
    })
}

export default CategorySetList;
