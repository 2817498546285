/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
    Button,
    Grid,
    Icon,
    Image,
    Dropdown,
    Header,
    Label,
    Divider,
    Statistic,
    Message,
    PlaceholderParagraph,
    PlaceholderLine,
    PlaceholderHeader,
    Placeholder,
    Segment,
} from 'semantic-ui-react'
import Barcode from 'react-barcode'
import { useContext, useEffect, useMemo, useState } from 'react'
import { UltyModalWrapperContext } from '../../../Shared/UltyModalWrapper/UltyModalWrapperContext'
import { useTranslation } from 'react-i18next'
import { getMerchantItems } from '../../../../services/Item/Item.service'
import { UltyMoney } from '@ulty/ulty-ui'
import useUser from '../../../Shared/UserProvider/useUser'
import { OrderDetailContext } from '../OrderDetailContext'
import PropTypes from 'prop-types'

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    margin-bottom: 2rem;
    margin-top: 1.5rem;
`

const SegmentPlaceholder = () => {
    return (
      <Segment raised>
          <Placeholder>
              <PlaceholderHeader image>

                  <PlaceholderLine/>
                  <PlaceholderLine/>
              </PlaceholderHeader>
              <PlaceholderParagraph>
                  <PlaceholderLine length="medium"/>
                  <PlaceholderLine length="short"/>
              </PlaceholderParagraph>
          </Placeholder>
      </Segment>
    )
}

const ItemDisplay = ({item}) => {
    const [t] = useTranslation()
    const {user} = useUser()

    const currency = user.getCompanyCurrency()
    const locale = user.getCompanyLocale()

    const image = item?.image || item?.images?.[0]?.url
    const inventory = item?.inventory || item?.inventories?.[0]

    return <Grid>
        <Label color="green" attached="top" icon="barcode" content={t('order.pickup.scanned_label')}></Label>
        <Grid.Row>
            <Grid.Column tablet={8} css={css`padding-bottom: 0;`}>
                <Image centered src={image}/>
            </Grid.Column>
            <Grid.Column tablet={8} css={css`
                display: flex !important;
                flex-direction: column;
                padding-bottom: 0;

                label {
                    font-weight: bold;
                }
            `}>
                <div css={css`padding-top: 10px`}>
                    <h4>{item.object?.name || item.name}</h4>
                </div>
                <Divider horizontal></Divider>
                <div css={css`
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    align-items: center;
                    height: 100%;
                    max-height: 95px;
                    padding-bottom: 10px;

                    .statistic {
                        margin: 0;
                        flex: 1;
                    }
                `}>
                    <Statistic size="mini">
                        <Statistic.Label
                          css={css`font-size: .8em !important;`}>{t('order.pickup.wrong_item_details.price')}</Statistic.Label>
                        <Statistic.Value css={css`font-size: 1.8rem !important`}>
                            <UltyMoney
                              readonly
                              amount={inventory.sellPrice || item.basePrice}
                              currencyCode={currency}
                              locale={locale}
                            />
                        </Statistic.Value>
                    </Statistic>
                </div>

            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <div css={css`
                display: flex;
                flex-direction: row;
                width: 100%;
                align-items: center;
                justify-content: space-around;
                height: 100%;
                max-height: 95px;

                .statistic {
                    margin: 0;
                    flex: 1;
                }
            `}>
                {item.object?.barcode && (
                  <div css={css`flex: 2;
                      text-align: center;`}>
                      <Barcode value={item.object.barcode} format="EAN13" displayValue={true} fontSize={15} height={60}
                               width={1.2}/>
                  </div>
                )}
            </div>
        </Grid.Row>
    </Grid>
}

ItemDisplay.propTypes = {
    item: PropTypes.shape({
        object: PropTypes.shape({
            name: PropTypes.string,
            barcode: PropTypes.string,
        }),
        image: PropTypes.string,
        images: PropTypes.arrayOf(
          PropTypes.shape({
              url: PropTypes.string,
          }),
        ),
        inventory: PropTypes.object,
        inventories: PropTypes.array,
        basePrice: PropTypes.number,
    }).isRequired,
}

const OrderPickupWrongItem = ({replacableOrderItems, barcode, onSubmit}) => {
    const [t] = useTranslation()
    const {handleUltyModalWrapper} = useContext(UltyModalWrapperContext)
    const [barcodeItem, setBarcodeItem] = useState(undefined)
    const {user} = useUser()

    const currency = user.getCompanyCurrency()
    const locale = user.getCompanyLocale()
    useEffect(() => {
        (async () => {
            const [item] = (await getMerchantItems({search: barcode, page: 1, size: 1}))?.items ?? []
            setBarcodeItem(item ?? null)
        })()
    }, [])

    return (
      <>
          <Container>
              <Message
                error
                icon="ban"
                header={t('order.pickup.wrong_item', {barcode})}
              />
          </Container>
          <Segment textAlign="center">
              {barcodeItem === undefined && <SegmentPlaceholder/>}
              {barcodeItem === null && <Label>{t('order.pickup.wrong_item_details.unknown_product')}</Label>}
              {barcodeItem && <ItemDisplay item={barcodeItem}/>}
          </Segment>
          {barcodeItem && <>
              {replacableOrderItems?.length === 0 && <Segment textAlign="center">
                  <Header css={css`white-space: pre-wrap;`}>
                      {t('order.pickup.wrong_item_details.no_substitution_available')}
                  </Header>
              </Segment>}
              {replacableOrderItems?.length > 0 && <Segment textAlign="center">
                  <Header>{t('order.pickup.wrong_item_details.substitute_item_label')}</Header>
                  <Dropdown
                    fluid
                    scrolling
                    placeholder={t('order.pickup.wrong_item_details.substitute_item_placeholder')}
                    selection
                  >
                      <Dropdown.Menu>
                          {replacableOrderItems.map((item, index) => (
                            <Dropdown.Item
                              key={item.id}
                              onClick={() => {
                                  handleUltyModalWrapper(false, null)
                                  onSubmit('SUBSTITUTE', {barcode, orderItem: item})
                              }}>
                                <Grid>
                                    <Grid.Column tablet={6}>
                                        <Image size="small" src={item.image || item.images?.[0]?.url}/>
                                    </Grid.Column>
                                    <Grid.Column tablet={8}>
                                        <p>
                                            <strong>
                                                {item.object?.name || item.name}
                                            </strong>
                                            <br/>
                                            <Icon name="barcode" color="grey"/>{item.object?.barcode}
                                            <br/>
                                            <strong>
                                                <UltyMoney
                                                  readonly
                                                  inline
                                                  amount={item.unitPrice}
                                                  currencyCode={currency}
                                                  locale={locale}
                                                />
                                            </strong>
                                        </p>
                                    </Grid.Column>
                                </Grid>
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                  </Dropdown>
              </Segment>
              }
          </>
          }

          <div style={{textAlign: 'right'}}>
              <Button
                onClick={() => {
                    onSubmit('CANCEL')
                    handleUltyModalWrapper(false, null)
                }}
              >
                  {t('global.cancel')}
              </Button>
          </div>
      </>
    )
}


OrderPickupWrongItem.propTypes = {
    replacableOrderItems: PropTypes.arrayOf(ItemDisplay.propTypes.item).isRequired,
    barcode: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
}


export default OrderPickupWrongItem
