/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { Form, Header, Button, Checkbox, Radio, Input, Message, Icon } from 'semantic-ui-react'
import { notify } from 'react-notify-toast'
import { patchMerchant } from '../../../services/PointOfSale/PointOfSale.service'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import useUser from '../../Shared/UserProvider/useUser'
import { UltyDropdown, UltyInputText } from '@ulty/ulty-ui'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery'
import { MOBILE_THRESHOLD } from '../../../constants'

const OptionsForm = ({ pointOfSale }) => {
    const [t] = useTranslation()
    const {user} = useUser()
    const [canCreateItems, setCanCreateItems] = useState(false)
    const [canCreateCategories, setCanCreateCategories] = useState(false)
    const [canExtendCategories, setCanExtendCategories] = useState(false)
    const [stockUnavailableThreshold, setStockUnavailableThreshold] = useState(0)
    const [itemInventoryUnavailableUntilDurationHour, setItemInventoryUnavailableUntilDurationHour] = useState(null)
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);

    useEffect(() => {
        setCanCreateItems(pointOfSale.canCreateItems)
        setCanCreateCategories(pointOfSale.canCreateCategories)
        setCanExtendCategories(pointOfSale.canExtendCategories)
        setStockUnavailableThreshold(pointOfSale.stockUnavailableThreshold)
        setItemInventoryUnavailableUntilDurationHour(pointOfSale.itemInventoryUnavailableUntilDurationHour)
    }, [pointOfSale])

    const queryClient = useQueryClient()
    const patchPosMutation = useMutation({
        mutationFn: () => patchMerchant(pointOfSale.id, {
            canCreateItems,
            canCreateCategories,
            canExtendCategories,
            stockUnavailableThreshold,
            itemInventoryUnavailableUntilDurationHour,
        }),
        onSuccess: async () => {
            notify.show(t('global.registerSuccess'), 'success')
            await queryClient.invalidateQueries({queryKey: ['pointofsales', pointOfSale.id]})
        },
        onError: () => {
            notify.show(t('global.anErrorOccurred'), 'error')
        },
    })

    return (
      <Form>
          <Header
            css={css`
                &.ui.header {
                    margin-bottom: 2rem;
                }
            `}
          >{t('administration.merchants.manage_merchant')}</Header>
            <UltyInputText
              readonly
              label={t('administration.merchants.unavailable_threshold')}
            />
              <Form.Field
                css={css`
                    &.field.wide {
                        margin-top: 1em;
                        margin-bottom: 2rem;
                    }
                `}
                width={isMobile ? 6 : 3}
                control={Input}
                name="unavailable_threshold"
                value={stockUnavailableThreshold}
                onChange={(evt, dt) => setStockUnavailableThreshold(dt.value)}
                placeholder={t('administration.merchants.unavailable_threshold_placeholder')}
              />

              <Form.Field>
                  <Checkbox
                    css={css`
                        &.ui.toggle.checkbox > label {
                            font-weight: 700;
                            font-size: .92857143em;
                        }
                    `}
                    toggle
                    checked={itemInventoryUnavailableUntilDurationHour !== null}
                    label={itemInventoryUnavailableUntilDurationHour !== null ?
                      t('administration.merchants.default_unavailable_until_duration.label.active') :
                      t('administration.merchants.default_unavailable_until_duration.label.inactive')}
                    onChange={(evt, {checked}) => {
                        setItemInventoryUnavailableUntilDurationHour(checked ? 24 : null)
                    }}
                  />
              </Form.Field>

              <Form.Field width={isMobile ? 6 : 3}>
                  {itemInventoryUnavailableUntilDurationHour !== null && (
                    <UltyDropdown
                      loading={patchPosMutation.isPending}
                      multiple={false}
                      compact
                      options={[{
                          key: 24,
                          value: 24,
                          text: t('administration.merchants.default_unavailable_until_duration.options.x_days', {count: 1}),
                      }, {
                          key: 72,
                          value: 72,
                          text: t('administration.merchants.default_unavailable_until_duration.options.x_days', {count: 3}),
                      }, {
                          key: 120,
                          value: 120,
                          text: t('administration.merchants.default_unavailable_until_duration.options.x_days', {count: 5}),
                      }, {
                          key: 168,
                          value: 168,
                          text: t('administration.merchants.default_unavailable_until_duration.options.x_weeks', {count: 1}),
                      }]}
                      onChange={(e, {value}) => {
                          setItemInventoryUnavailableUntilDurationHour(value)
                      }}
                      value={itemInventoryUnavailableUntilDurationHour}
                    />
                  )}
              </Form.Field>

          <Message
            compact
            icon
            css={css`
                &.ui.message {
                    margin-top: 0;
                }

                &.ui.icon.message > .icon:not(.close) {
                    font-size: 2em;
                }
            `}
          >
              <Icon name='info circle' />
              {t('administration.merchants.default_unavailable_until_duration.disclaimer')}
          </Message>

          {user.provider.type === 'COMPANY' && (
            <>
                <Form.Field>
                    <label>{t('administration.merchants.manage_items')}</label>
                </Form.Field>
                <Form.Field>
                    <Checkbox toggle checked={canCreateItems} onChange={(evt, {checked}) => setCanCreateItems(checked)}
                              label={t('administration.merchants.authorize_products')}/>
                </Form.Field>

                <Form.Field>
                    <label>{t('administration.merchants.manage_sets')}</label>
                </Form.Field>
                <Form.Field>
                    <Radio
                      label={t('administration.merchants.sets_read_only')}
                      name="checked"
                      checked={!canCreateCategories && !canExtendCategories}
                      onChange={() => {
                          setCanCreateCategories(false)
                          setCanExtendCategories(false)
                      }}
                    />
                </Form.Field>
                <Form.Field>
                    <Radio
                      label={t('administration.merchants.sets_extend')}
                      name="checked"
                      checked={!canCreateCategories && canExtendCategories}
                      onChange={() => {
                          setCanCreateCategories(false)
                          setCanExtendCategories(true)
                      }}
                    />
                </Form.Field>
                <Form.Field>
                    <Radio
                      label={t('administration.merchants.sets_create')}
                      name="checked"
                      checked={canCreateCategories}
                      onChange={() => {
                          setCanCreateCategories(true)
                          setCanExtendCategories(true)
                      }}
                    />
                </Form.Field>
            </>
          )}

          <div style={{textAlign: 'right'}}>
              <Button
                color="teal"
                loading={patchPosMutation.isPending}
                disabled={patchPosMutation.isPending}
                onClick={(e) => {
                    e.preventDefault()
                    patchPosMutation.mutate()
                }}
                >{t('global.save')}
              </Button>
          </div>
      </Form>
    )
}

OptionsForm.propTypes = {
    pointOfSale: PropTypes.object.isRequired,
}

export default OptionsForm
