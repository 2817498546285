import { registerDevice } from "../services/Device/Device.service";
import useLocalStorage from 'beautiful-react-hooks/useLocalStorage'

export const registerWithPush = async (token, manual = false) => {
    const {device} = getDeviceInfoFromStorage();
    const response = await registerDevice(token, manual, device?.soundMode,device?.id ?? undefined);
    localStorage.setItem("TOKEN_SYNC", `${(new Date()).getTime()}`);
    const result = {...response, type:"PUSH"}
    localStorage.setItem("PUSH-TOKEN", JSON.stringify(result));

    return result
};

export const registerWithPolling = async () => {
    const {device} = getDeviceInfoFromStorage();
    const response = await registerDevice("", false, device?.soundMode,device?.id ?? undefined);
    localStorage.setItem("TOKEN_SYNC", `${(new Date()).getTime()}`);
    const result = {...response, type:"POLLING"}
    localStorage.setItem("PUSH-TOKEN", JSON.stringify(result));

    if(window.Android && window.Android.storeAuthToken){
        window.Android.storeAuthToken(response.authToken);
    }

    return result
}

export const retriggerRegistration =  async () => {
    const {device} = getDeviceInfoFromStorage();
    const {type:notificationType,id} = device || {}
    if(!id) return;

    if (window.Android.storeAuthToken && (!notificationType || notificationType === "POLLING")) {
        await registerWithPolling();
      } else if(window.Android.getToken && (!notificationType || notificationType === "PUSH")) {
        const token = window.Android.getToken();
        await registerWithPush(token,false);
      }
}

export const retrieveLastSync = () => {
    const {lastSynced} = getDeviceInfoFromStorage();
    return lastSynced
}

export const getSoundMode = () => {
    const deviceStr = localStorage.getItem("PUSH-TOKEN");
    const device = deviceStr ? tryParse(deviceStr) : null;
    return device?.soundMode
}

export const getDeviceInfoFromStorage = () => {
    const lastSyncedStr = localStorage.getItem("TOKEN_SYNC");
    const deviceStr = localStorage.getItem("PUSH-TOKEN");
    const lastSynced = lastSyncedStr ? parseInt(lastSyncedStr) : null;
    const device = deviceStr ? tryParse(deviceStr) : null;
    return { lastSynced, device };
}

export const useDeviceInfo = () => {
    const [deviceData, setDeviceData] = useLocalStorage("PUSH-TOKEN",null);
    return {deviceData, setDeviceData}
}

const tryParse = (str) => {
    try{
        return JSON.parse(str);
    }
    catch(e){
        return null;
    }
}
