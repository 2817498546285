import { Form, Header, Segment } from 'semantic-ui-react'
import { UltyDropdown } from '@ulty/ulty-ui'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { notify } from 'react-notify-toast'
import { useTranslation } from 'react-i18next'
import { patchDevice } from '../../../services/Device/Device.service'
import { useDeviceInfo } from '../../../devices/device.provider'

const DeviceOptions = ({ device }) => {
    const [t] = useTranslation()
    const queryClient = useQueryClient()
    const {setDeviceData} = useDeviceInfo()

    const patchDeviceMutation = useMutation({
        mutationFn: (patch) => {
            return patchDevice(device.id, patch)
        },
        onSuccess: async ({patchedDevice},f) => {
            if (patchedDevice) {
                setDeviceData(prev => ({...prev, ...patchedDevice}))
            }
            await queryClient.invalidateQueries({ queryKey: ['devices', device.id] })
            notify.show(t('global.registerSuccess'), 'success')
        },
        onError: () => {
            notify.show(t('global.anErrorOccurred'), 'error')
        }
    })

    return (
      <Segment>
          <Header>{t('administration.device.notifications.notifications')}</Header>
          <Form>
              <UltyDropdown
                label={t('administration.device.notifications.duration')}
                options={[
                    {key: 'short', text: t('administration.device.notifications.short'), value: 'SHORT'},
                    {key: 'long', text: t('administration.device.notifications.long'), value: 'LONG'},
                ]}
                multiple={false}
                loading={patchDeviceMutation.isPending}
                onChange={(e, { value }) => {
                    patchDeviceMutation.mutate({
                        soundMode: value
                    })
                }}
                value={device.soundMode}
              />
          </Form>
      </Segment>
    )
}

export { DeviceOptions }
