import {call, METHODS} from '../http-client';
import moment from 'moment';

const ROUTE_PREFIX = 'pointofsales';

export const getPointOfSale = async (id) => {
    try {
        return await call(METHODS.GET, ROUTE_PREFIX, `${id}`);
    } catch (e) {
        console.error(`getPointOfSale error: ${e.message}`);
        throw e;
    }
}

export const getBrandsOfAPos = async (posId) => {
    try {
        return await call(METHODS.GET, ROUTE_PREFIX, `${posId}/brands`);
    } catch (e) {
        console.error(`getBrandsOfAPos error: ${e.message}`);
        throw e;
    }
}

export const getPlatformsOfPosBrand = async (posId, brandId) => {
    try {
        return await call(METHODS.GET, ROUTE_PREFIX, `${posId}/brands/${brandId}/platforms`);
    } catch (e) {
        console.error(`getPlatformsOfPosBrand error: ${e.message}`);
        throw e;
    }
}

export const getPosBrandPlatform = async (posId, brandId, platformId) => {
    try {
        return await call(METHODS.GET, ROUTE_PREFIX, `${posId}/brands/${brandId}/platforms/${platformId}`);
    } catch (e) {
        console.error(`getPosBrandPlatform error: ${e.message}`);
        throw e;
    }
}

export const patchPosBrandPlatform = async (posBrandPlatform) => {
    try {
        const {posId, brandId, platformId} = posBrandPlatform;
        return await call(METHODS.PATCH, ROUTE_PREFIX, `${posId}/brands/${brandId}/platforms/${platformId}`, posBrandPlatform);
    } catch (e) {
        console.error(`updatePosBrandPlatform error: ${e.message}`);
        throw e;
    }
}


export const saveMarkupSetup = async (markupSetup) => {
    try {
        const {posId, brandId, platformId} = markupSetup.posBrandPlatform;
        return await call(METHODS.POST, ROUTE_PREFIX,  `${posId}/brands/${brandId}/platforms/${platformId}/markupSetups`, markupSetup)
    } catch (e) {
        console.error(`saveMarkupSetup error: ${e.message}`);
        throw e;
    }
}

export const getMarkupSetup = async (posBrandPlatform) => {
    try {
        const {posId, brandId, platformId} = posBrandPlatform;
        return await call(METHODS.GET, ROUTE_PREFIX, `${posId}/brands/${brandId}/platforms/${platformId}/markupSetups`)
    } catch (e) {
        console.error(`getMarkupSetup error: ${e.message}`)
        throw e
    }
}

export const deleteMarkupSetup = async (posBrandPlatform, markupSetupId) => {
    try {
        const {posId, brandId, platformId} = posBrandPlatform;
        return await call(METHODS.DELETE, ROUTE_PREFIX, `${posId}/brands/${brandId}/platforms/${platformId}/markupSetups/${markupSetupId}`)
    } catch (e) {
        console.error(`deleteMarkupSetup error: ${e.message}`)
        throw e
    }
}

export const patchMerchant = async (id, patch) => {
    return await call(METHODS.PATCH, ROUTE_PREFIX, `${id}`, patch );
}

export const getMerchantStatus = async () => {
    return await call(METHODS.GET, ROUTE_PREFIX, `busy` );
}

export const setBusyMode = async ({ status }) => {
    return await call(METHODS.PUT, ROUTE_PREFIX, `busy`, {
        status
    } );
}

export const openPosBrandPlatform = async (posId, brandId, platformId) => {
    return await call(METHODS.PUT, ROUTE_PREFIX, `${posId}/brands/${brandId}/platforms/${platformId}/open`, {})
}

const buildPosOffDaysFromResponse = (response) => {
    return response.map(dayOff => ({
        id: dayOff.id,
        posId: dayOff.posId,
        from: moment(dayOff.from),
        to: moment(dayOff.to),
    }))
}

export const getPosOffDays = async (id) => {
    const response = await call(METHODS.GET, ROUTE_PREFIX, `${id}/offDays`);
    return buildPosOffDaysFromResponse(response);
}

export const setPosOffDays = async (id, offDays) => {
    const response = await call(METHODS.PUT, ROUTE_PREFIX, `${id}/offDays`, {
        offDays
    });
    return buildPosOffDaysFromResponse(response);
}

export const getPosStocks = async (id) => {
    return await call(METHODS.GET, ROUTE_PREFIX, `${id}/stock-history` );
}

export const getPosEvents = async (id) => {
    return await call(METHODS.GET, ROUTE_PREFIX, `${id}/events` );
}
