/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { Tab, Form, Button, Header, Message, Grid, Segment } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { notify } from 'react-notify-toast'
import { usePrinter } from '../../../printing/services/printer.service'
import { AdminPrintingOptions } from '../../../printing/components/AdminPrintingOptions/AdminPrintingOptions'
import {
    registerWithPolling,
    registerWithPush,
    useDeviceInfo,
} from '../../../devices/device.provider'
import { DeviceOptions } from './DeviceOptions'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getDevice, unregisterDevice } from '../../../services/Device/Device.service'
import useUser from '../../Shared/UserProvider/useUser'

const AdminDevice = () => {
    const [t] = useTranslation()
    const [loading, setLoading] = useState(false)
    const {deviceData, setDeviceData} = useDeviceInfo()
    const {printerInfo} = usePrinter()
    const queryClient = useQueryClient()
    const { user } = useUser()

    useEffect(() => {
        const handle = async (evt) => {
            setLoading(true)
            const token = evt.token
            await registerWithPush(token, true)

            notify.show(t('administration.device.success'), 'success')

            setLoading(false)
        }
        window.addEventListener('FirebaseToken', handle, false)
        return () => window.removeEventListener('FirebaseToken', handle)
    }, [t])

    const connectMutation = useMutation({
        mutationFn: async () => {
            if (window.webkit?.messageHandlers) {
                window.webkit.messageHandlers.getToken.postMessage('')
                return
            }

            const token = window.Android.getToken()
            return await registerWithPush(token, true)
        },
        onSuccess: (device) => {
            setDeviceData(device)
            notify.show(t('administration.device.link_success'), 'success')
        },
        onError: () => {
            notify.show(t('global.anErrorOccurred'), 'error')
        },
    })

    const authConnectMutation = useMutation({
        mutationFn: registerWithPolling,
        onSuccess: (device) => {
            setDeviceData(device)
            notify.show(t('administration.device.link_success'), 'success')
        },
        onError: () => {
            notify.show(t('global.anErrorOccurred'), 'error')
        },
    })

    const unregisterDeviceMutation = useMutation({
        mutationFn: () => {
            return unregisterDevice(deviceData.id)
        },
        onSuccess: () => {
            localStorage.removeItem('PUSH-TOKEN')
            localStorage.removeItem('TOKEN_SYNC')
            queryClient.removeQueries({
                queryKey: ['devices', deviceData.id],
            })
            setDeviceData(null)
            notify.show(t('administration.device.unlink_success'), 'success')
        },
        onError: () => {
            notify.show(t('global.anErrorOccurred'), 'error')
        },
    })

    const {data: device} = useQuery({
        queryKey: ['devices', +deviceData?.id],
        queryFn: () => {
            return getDevice(deviceData.id)
        },
        enabled: !!deviceData?.id,
    })

    return (
      <Tab.Pane>
          <Segment basic>
              <Form>
                  <Header>{t('administration.device.header')}</Header>
                  <Grid>
                      <Grid.Column width={16}>
                          {device && (
                            <>
                                <Message color="teal">{t('administration.device.already_linked')}</Message>
                                {user.provider?.role.name === 'POWER_USER' &&
                                  <Button
                                    css={css`
                                    &.ui.button {
                                        margin-bottom: 1rem;
                                    }
                                  `}
                                    disabled={unregisterDeviceMutation.isPending}
                                    loading={unregisterDeviceMutation.isPending}
                                    size="tiny"
                                    onClick={unregisterDeviceMutation.mutate}>{t('administration.device.unlink_from_account')}
                                  </Button>
                                }
                            </>
                          )}
                          <Button disabled={connectMutation.isPending} loading={connectMutation.isPending} color="teal"
                                  size="tiny"
                                  onClick={connectMutation.mutate}>{t('administration.device.link_to_account')}</Button>
                      </Grid.Column>

                      {window.Android?.storeAuthToken && window.Android?.acknowledgeOrder && (
                        <Grid.Column width={16}>
                            <Button disabled={authConnectMutation.isPending} loading={authConnectMutation.isPending}
                                    color="teal" size="tiny"
                                    onClick={authConnectMutation.mutate}>{t('administration.device.link_to_auth_account')}</Button>
                        </Grid.Column>
                      )}

                      {window.Android?.quit && (
                        <Grid.Column width={16}>
                            <Button disabled={loading} loading={loading} color="teal" size="tiny" onClick={() => {
                                window.Android.quit()
                            }}>{t('administration.device.quit')}</Button>
                        </Grid.Column>
                      )}
                      {window.Android?.resetToken && (
                        <Grid.Column width={16}>
                            <Button disabled={loading} loading={loading} color="teal" size="tiny" onClick={() => {
                                window.Android.resetToken()
                            }}>{t('administration.device.reset')}</Button>
                        </Grid.Column>
                      )}
                  </Grid>
              </Form>
          </Segment>

          {device && window.Android?.handleShortSound && (
            <DeviceOptions device={device}/>
          )}

          {printerInfo.isDeviceCompatible && (<AdminPrintingOptions/>)}
      </Tab.Pane>
    )
}

export default AdminDevice
