import {call, getFileContent, METHODS} from '../http-client';

const ROUTE_PREFIX = 'categories';

export const canSaveCategory = (category) => {
    return !!category.name;
}

export const isCategoryProvidedByUserProvider = (category, user) => {
    return category.provider.id === user.provider.id
}

export const getCategorySets = async () => {
    try {
        return await call(METHODS.GET, ROUTE_PREFIX, '');
    } catch (e) {
        console.error(`getCategorySets error: ${e.message}`);
        throw e;
    }
}

export const exportCategorySet = async (setId) => {
    try {
        return await getFileContent(`${ROUTE_PREFIX}/${setId}/export`, {
            responseType: 'arraybuffer'
        });
    } catch (e) {
        console.error(`exportSet error: ${e.message}`);
        throw e;
    }
}

export const getCategorySlots = async (setId) => {
    try {
        return await call(METHODS.GET, ROUTE_PREFIX, `${setId}/slots`);
    } catch (e) {
        console.error(`getCategorySlots error: ${e.message}`);
        throw e;
    }
}

export const getCategoryLinks = async () => {
    try {
        return await call(METHODS.GET, ROUTE_PREFIX, `links`);
    } catch (e) {
        console.error(`getCategoryLinks error: ${e.message}`);
        throw e;
    }
}

export const createCategoryLinks = async (data) => {
    try {
        return await call(METHODS.POST, ROUTE_PREFIX, `links`, data);
    } catch (e) {
        console.error(`createCategoryLinks error: ${e.message}`);
        throw e;
    }
}

export const updateCategoryLinksSet = async (id, setId) => {
    try {
        return await call(METHODS.PUT, ROUTE_PREFIX, `links/${id}/set`, {
            setId
        });
    } catch (e) {
        console.error(`updateCategoryLinksSet error: ${e.message}`);
        throw e;
    }
}

export const updateCategoryLinksMerchants = async (id, merchants) => {
    try {
        return await call(METHODS.PUT, ROUTE_PREFIX, `links/${id}/merchants`, {
            merchants
        });
    } catch (e) {
        console.error(`updateCategoryLinksSet error: ${e.message}`);
        throw e;
    }
}

export const updateCategorySlot = async (setId, slotId, content) => {
    try {
        return await call(METHODS.PUT, ROUTE_PREFIX, `${setId}/slots/${slotId}`, content);
    } catch (e) {
        console.error(`getCategorySlots error: ${e.message}`);
        throw e;
    }
}

export const removeCategorySlot = async (setId, slotId) => {
    try {
        return await call(METHODS.DELETE, ROUTE_PREFIX, `${setId}/slots/${slotId}`);
    } catch (e) {
        console.error(`removeCategorySlot error: ${e.message}`);
        throw e;
    }
}

export const importCategories = async (setId, slotId, fromSetId, fromSlotId, allCategories, category, allSetCategories) => {
    try {
        return await call(
            METHODS.POST,
            ROUTE_PREFIX,
            `${setId}/slots/${slotId}/category/import`,
            {
                fromSetId, fromSlotId, allCategories, category, allSetCategories
            });
    } catch (e) {
        console.error(`importCategories error: ${e.message}`);
        throw e;
    }
}

export const mergeFile = async (setId,file) => {
    try {
        return await call(METHODS.POST, ROUTE_PREFIX, `${setId}/merge`, file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    } catch (e) {
        console.error(`mergeFile error: ${e.message}`);
        throw e;
    }
}

export const saveCategorySlot = async (setId, slot) => {
    try {
        return await call(METHODS.POST, ROUTE_PREFIX, `${setId}/slots`, slot);
    } catch (e) {
        console.error(`saveCategorySlot error: ${e.message}`);
        throw e;
    }
}

export const saveCategorySet = async (data) => {
    try {
        return await call(METHODS.POST, ROUTE_PREFIX, ``, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    } catch (e) {
        console.error(`saveCategorySet error: ${e.message}`);
        throw e;
    }
}

export const updateCategorySet = async (setId, data) => {
    try {
        return await call(METHODS.PUT, ROUTE_PREFIX, `${setId}`, data);
    } catch (e) {
        console.error(`updateCategorySet error: ${e.message}`);
        throw e;
    }
}

export const removeCategorySet = async (id) => {
    try {
        return await call(METHODS.DELETE, ROUTE_PREFIX, `${id}`);
    } catch (e) {
        console.error(`removeCategorySet error: ${e.message}`);
        throw e;
    }
}

export const getCategories = async (setId, slotId) => {
    try {
        return await call(METHODS.GET, ROUTE_PREFIX, `${setId}/slots/${slotId}/categories`);
    } catch (e) {
        console.error(`getCategories error: ${e.message}`);
        throw e;
    }
}

export const saveCategory = async (setId, slotId, category) => {
    try {
        return await call(
            category.id === 0 ? METHODS.POST : METHODS.PUT, ROUTE_PREFIX,
            `${setId}/slots/${slotId}/category`,
            category);
    } catch (e) {
        console.error(`saveCategory error: ${e.message}`);
        throw e;
    }
}

export const updateCategoryPosition = async (setId, slotId, category) => {
    try {
        return await call(
            METHODS.PUT, ROUTE_PREFIX,
            `${setId}/slots/${slotId}/categoryPosition`,
            category);
    } catch (e) {
        console.error(`updateCategoryPosition error: ${e.message}`);
        throw e;
    }
}

export const deleteCategory = async (setId, slotId, categoryId) => {
    try {
        return await call(
            METHODS.DELETE,
            ROUTE_PREFIX,
            `${setId}/slots/${slotId}/category/${categoryId}`)
    } catch (e) {
        console.error(`deleteCategory error: ${e.message}`);
        throw e;
    }
}

export const deleteCategoryLink = async (id) => {
    try {
        return await call(
            METHODS.DELETE,
            ROUTE_PREFIX,
            `links/${id}`)
    } catch (e) {
        console.error(`deleteCategoryLink error: ${e.message}`);
        throw e;
    }
}

export const saveMarkupSetup = async (markupSetup) => {
    try {
        return await call(METHODS.POST, ROUTE_PREFIX, `${markupSetup.categorySetLinkId}/markupSetups`, markupSetup)
    } catch (e) {
        console.error(`saveMarkupSetup error: ${e.message}`);
        throw e;
    }
}

export const getMarkupSetup = async (setId) => {
    try {
        const TO_REMOVE =  await call(METHODS.GET, ROUTE_PREFIX, `${setId}/markupSetups`)
        //TO_REMOVE[0].hours = [{start_time: "09:00", end_time: "18:00"}];
        return TO_REMOVE

    } catch (e) {
        console.error(`getMarkupSetup error: ${e.message}`)
        throw e
    }
}

export const deleteMarkupSetup = async (setId, markupSetupId) => {
    try {
        return await call(METHODS.DELETE, ROUTE_PREFIX, `${setId}/markupSetups/${markupSetupId}`)
    } catch (e) {
        console.error(`deleteMarkupSetup error: ${e.message}`)
        throw e
    }
}

export const getWeeklyMarkupPricesOfItem = async (setLinkId, itemId,currentMarkupSetupId = undefined) => {
    try {
        const queryParams = new URLSearchParams();
        if(currentMarkupSetupId){
            queryParams.set('currentMarkupSetupId',currentMarkupSetupId);
        }
        return await call(METHODS.GET, ROUTE_PREFIX, `${setLinkId}/items/${itemId}/markupSetups?${queryParams}`)
    } catch (e) {
        console.error(`getWeeklyMarkupPricesOfItem error: ${e.message}`)
        throw e
    }
}

export const getItemsFromCategory = async (categoryId) => {
    try {
        return await call(METHODS.GET, ROUTE_PREFIX, `${categoryId}/items`)
    } catch (e) {
        console.error(`getItemsFromCategory error: ${e.message}`)
        throw e
    }
}
