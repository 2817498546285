/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Checkbox, Divider, Grid, Header, Image, Label, Segment, Statistic, Table } from 'semantic-ui-react'
import { ScanInfo } from '../OrderScanPicking/ScanInfo'
import Zoom from 'react-medium-image-zoom'
import OrderItemRow from './OrderItemRow'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery'
import { MOBILE_THRESHOLD } from '../../../../constants'
import { formatToMaxLength } from '../../../../helpers/formatToMaxLength'
import useUser from '../../../Shared/UserProvider/useUser'
import { UltyMoney } from '@ulty/ulty-ui'
import Barcode from 'react-barcode'
import { useWebPSupportCheck } from 'react-use-webp-support-check'

const truncateString = (str, maxLength) => {
    return str?.length > maxLength ? formatToMaxLength(str, maxLength) : str
}

const OrderItemDescription = ({orderItem}) => {
    const maxLength = 50
    const description = truncateString(orderItem.object?.description || orderItem.description, maxLength)

    return (description && <div css={css`font-style: italic;
        font-size: 0.75em`}>{description}</div>)
}

const OrderItemDetail = ({orderItem}) => {
    const [t] = useTranslation()
    const {user} = useUser()

    return (
      <>
          <Divider horizontal>{t('order.order')}</Divider>
          <div css={css`
              display: flex;
              flex-direction: row;
              width: 100%;
              align-items: center;
              justify-content: space-around;
              height: 100%;
              max-height: 95px;
              padding-bottom: 10px;

              .statistic {
                  margin: 0;
                  flex: 1;
              }
          `}>
              <Statistic size="mini">
                  <Statistic.Label css={css`font-size: .8em !important;`}
                                   className={parseFloat(orderItem.quantity || 0) > 1 ? 'multi-items' : ''}>{t('order.quantity')}</Statistic.Label>
                  <Statistic.Value
                    className={parseFloat(orderItem.quantity || 0) > 1 ? 'multi-items' : ''}>{orderItem.quantity}</Statistic.Value>
              </Statistic>
              <Statistic size="mini">
                  <Statistic.Label
                    css={css`font-size: .8em !important;`}>{t('order.detail.unit_price')}</Statistic.Label>
                  <Statistic.Value css={css`font-size: 1.8rem !important`}>
                      <UltyMoney
                        readonly
                        amount={orderItem.unitPrice}
                        currencyCode={user.getCompanyCurrency()}
                        locale={user.getCompanyLocale()}
                      />
                  </Statistic.Value>
              </Statistic>
          </div>
          <div css={css`
              display: flex;
              flex-direction: row;
              width: 100%;
              align-items: center;
              justify-content: space-around;
              height: 100%;
              max-height: 95px;

              .statistic {
                  margin: 0;
                  flex: 1;
              }
          `}>
              {orderItem.object?.barcode && (
                <div css={css`flex: 2;
                    text-align: center;`}>
                    <Barcode value={orderItem.object.barcode} format="EAN13" displayValue={true} fontSize={15}
                             height={60} width={1.5}/>
                </div>
              )}
          </div>
      </>
    )
}

const buildModifiers = (modifiers, prefix = 0) => {
    return modifiers.map((m) => {
        let Pre = () => null
        if (m.modifiers && m.modifiers.length > 0) {
            Pre = () => buildModifiers(m.modifiers, prefix + 1)
        }

        return (
          <React.Fragment key={m.id}>
              <div style={{paddingLeft: `${prefix * 10}px`}}>{`- ${m.name} (${m.quantity}x)`}</div>
              <Pre/>
          </React.Fragment>
        )
    })
}

const OrderItemRich = ({
                           orderItem,
                           isModifying,
                           hideable,
                           isChecked,
                           onCheck,
                           scanInfo,
                           onAddOrderItemToFulfillmentIssues,
                           fulfillmentIssue,
                           relatedReplacement,
                           order,
                       }) => {
    const [t] = useTranslation()
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`)
    const supportsWebP = useWebPSupportCheck()
    const itemRef = useRef(null)

    useEffect(() => {
        if (isChecked && itemRef.current) {
            itemRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [isChecked])

    const getItemImage = (item) => {
        if (!item.image) {
            return ''
        }

        if (item.image.indexOf('images.ulty.fr') === -1) {
            return item.image
        }

        const path = item.image.replace(/http[s]?:\/\/images.ulty.fr\//, '')
        if (supportsWebP) {
            return `https://thumbnails.ulty.fr/250x250/webp/${path}`
        }
        return `https://thumbnails.ulty.fr/250x250/png/${path}`
    }

    return (
      <div ref={itemRef}>
          <div css={css`
          margin-right: ${isMobile ? '-0.8rem' : '0'} !important;
          margin-left: ${isMobile ? '-0.8rem' : '0'} !important;
      `}>
              <Header as="h5" attached="top" block css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 10px !important;

              ${orderItem.quantity === 0 && 'text-decoration: line-through;'}
          `}>
                  <div>{`${orderItem.object?.name || orderItem.name}`}
                      {(scanInfo.isScanned || orderItem.fulfillmentIssue) && (
                        <>
                            <br/>
                            {scanInfo.isScanned &&
                              <ScanInfo orderItem={orderItem} scanValue={scanInfo.scannedQuantity}/>
                            }

                            {orderItem.fulfillmentIssue &&
                              <Label
                                size="mini"
                                color="grey">{t(`order.fulfillment_issue.${orderItem.fulfillmentIssue}`)}
                              </Label>
                            }
                        </>
                      )}
                  </div>
                  {onCheck && (
                    <div>
                        <Checkbox
                          checked={isChecked}
                          onChange={onCheck}
                        />
                    </div>
                  )}
              </Header>

              {(!isChecked || !hideable) && (
                <Segment attached key={orderItem.id}>
                    {orderItem.categoryName && (
                      <>
                          <Header as="h4">{orderItem.categoryName}</Header>
                          <Divider/>
                      </>
                    )}
                    <Grid>
                        <Grid.Column tablet={8} css={css` ${isMobile ? 'padding-bottom: 0;' : ''}`}>
                            <Zoom>
                                <Image src={getItemImage(orderItem)} css={css` max-height: 250px;
                                margin: auto; `}/>
                            </Zoom>

                        </Grid.Column>
                        <Grid.Column tablet={8} css={css`
                        display: flex !important;
                        flex-direction: column;

                        ${isMobile ? 'padding-bottom: 0;' : ''}
                        label {
                            font-weight: bold;
                        }
                    `}>
                            <div>{orderItem.object?.name || orderItem.name}</div>
                            <OrderItemDescription orderItem={orderItem}/>
                            {!isMobile && (
                              <>
                                  {orderItem.modifiers && buildModifiers(orderItem.modifiers)}
                                  <OrderItemDetail orderItem={orderItem}/>
                              </>
                            )}
                        </Grid.Column>

                        {isMobile && (
                          <>
                              {orderItem.modifiers && orderItem.modifiers.length > 0 && (
                                <Grid.Column width={16}>
                                    {orderItem.modifiers && buildModifiers(orderItem.modifiers)}
                                </Grid.Column>
                              )}
                              <Grid.Column width={16} css={css`
                              padding-top: 0 !important;
                              display: flex !important;
                              flex-direction: column !important;
                          `}>
                                  <OrderItemDetail orderItem={orderItem}/>
                              </Grid.Column>
                          </>
                        )}

                        {isModifying &&
                          <Grid.Row>
                              <Grid.Column>
                                  <Divider horizontal>{t('order.fulfillment_issue.fulfillment_issue')}</Divider>
                                  <Table>
                                      <Table.Body>
                                          <OrderItemRow
                                            {...fulfillmentIssue && {currentQuantity: fulfillmentIssue?.quantity}}
                                            order={order}
                                            orderItem={orderItem}
                                            displayOrderItemName={false}
                                            isModifying={isModifying}
                                            hasFulfillmentIssues={!!fulfillmentIssue}
                                            addOrderItemToFulfillmentIssues={onAddOrderItemToFulfillmentIssues}
                                            fulfillmentReplacement={relatedReplacement}
                                          />
                                      </Table.Body>
                                  </Table>
                              </Grid.Column>
                          </Grid.Row>
                        }
                    </Grid>
                </Segment>
              )}
          </div>

          {(relatedReplacement && !isModifying) && (
            <OrderItemRich
              orderItem={relatedReplacement}
              isModifying={false}
              hideable={hideable}
              scanInfo={scanInfo}
              onAddOrderItemToFulfillmentIssues={() => {
              }}
              fulfillmentIssue={fulfillmentIssue}
              relatedReplacement={undefined}
              order={order}
            />
          )}
      </div>
    )
}

export { OrderItemRich }
