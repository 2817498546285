import useMediaQuery from "beautiful-react-hooks/useMediaQuery";
import { MOBILE_THRESHOLD, TABLET_THRESHOLD } from "../../../constants";
import { useMemo } from "react";

export const useDeviceMediaQuery = () => {
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);
    const isTablet = useMediaQuery(`(max-width: ${TABLET_THRESHOLD}px)`);
    const isDesktop = useMemo(() => !isMobile && !isTablet, [isMobile, isTablet]);

    return {
        isMobile,
        isTablet,
        isDesktop
    };
};

