/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { Header, Icon, Loader, Table, Menu } from 'semantic-ui-react'
import { getPosEvents, getPosStocks } from '../../../services/PointOfSale/PointOfSale.service'
import formatDate from '../../../helpers/formatDate'
import { useTranslation } from 'react-i18next'
import useUser from '../../Shared/UserProvider/useUser'
import { formatDuration } from 'date-fns'

const AuditPos = ({pos}) => {
    const [t] = useTranslation()

    const {user} = useUser()

    const [loadingNumber, setLoadingNumber] = useState(0)
    const isLoading = loadingNumber > 0
    const [stocks, setStocks] = useState([])
    const [events, setEvents] = useState([])
    const [num, setNum] = useState(1)
    const [page, setPage] = useState(0)

    useEffect(() => {
        loadStocks()
        if (user?.isStaff) {
            loadEvents()
        }
    }, [user])

    const loadStocks = async () => {
        setLoadingNumber(x => x + 1)
        const poss = await getPosStocks(pos.id)
        await getPosEvents(pos.id)
        setStocks(poss)
        setLoadingNumber(x => x - 1)
    }

    const loadEvents = async () => {
        setLoadingNumber(x => x + 1)
        const poss = await getPosEvents(pos.id)
        setEvents(poss)
        setNum(parseInt(poss.length / 10) + 1)
        setPage(0)
        setLoadingNumber(x => x - 1)
    }

    const Event = ({event}) => {
        if (event.type === 'PATCH_POS') {
            return (
              <ul>
                  {(event.content?.patch?.serviceAvailability || []).flatMap(s => s.time_periods.map(tp => {
                      const value = `${s.day_of_week}: ${tp.start_time} -> ${tp.end_time}`
                      return (<li key={value}>{value}</li>)
                  }))}
              </ul>
            )
        }

        if (event.type === 'PAUSE') {
            return event.content.online ? 'SET OPEN' : 'SET CLOSE'
        }

        if (event.type === "POS_PAUSE_RESUME" && event.content.duration) {
            return formatDuration(event.content.duration)
        }


        if (event.type === 'RESET_OPENING') {
            return (<span>RE-OPEN INTEGRATION {event.content.posBrandsPlatformId ? event.content.posBrandsPlatformId :
              <span>{event.content.posBrandPlatform.platform.name} - {event.content.posBrandPlatform.brand.name}</span>}</span>)
        }

        return (
          <pre css={css`
              white-space: pre-wrap;
          `}>
          {JSON.stringify(event.content)}
      </pre>)
    }

    return (
      <>
          <Header>{t('administration.merchants.stocks.header')}</Header>

          <Table singleLine>
              <Table.Header>
                  <Table.Row>
                      <Table.HeaderCell>{t('administration.merchants.stocks.filename')}</Table.HeaderCell>
                      <Table.HeaderCell>{t('administration.merchants.stocks.date')}</Table.HeaderCell>
                      <Table.HeaderCell>{t('administration.merchants.stocks.size')}</Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
              </Table.Header>

              <Table.Body>
                  {isLoading && (
                    <Table.Row>
                        <Table.Cell colSpan={4} style={{position: 'relative', height: '50px'}}>
                            <Loader active/>
                        </Table.Cell>
                    </Table.Row>
                  )}

                  {!isLoading && stocks.sort((a, b) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime()).map(stock => (
                    <Table.Row key={stock.filename}>
                        <Table.Cell>{stock.filename}</Table.Cell>
                        <Table.Cell>{formatDate(stock.date)}</Table.Cell>
                        <Table.Cell>{(stock.size / 1000).toFixed(0)} Ko</Table.Cell>
                        <Table.Cell textAlign="center">
                            <a href={stock.url} target="_blank" rel="noreferrer"><Icon name="download"/></a>
                        </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
          </Table>
          {user.isStaff && (
            <>
                <Header>{t('administration.merchants.events.header')}</Header>
                <Table singleLine>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{t('administration.merchants.events.type')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('administration.merchants.events.date')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('administration.merchants.events.user')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('administration.merchants.events.content')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {isLoading && (
                          <Table.Row>
                              <Table.Cell colSpan={4} style={{position: 'relative', height: '50px'}}>
                                  <Loader active/>
                              </Table.Cell>
                          </Table.Row>
                        )}

                        {!isLoading && events.sort((a, b) => (new Date(b.createdAt)).getTime() - (new Date(a.createdAt)).getTime()).slice(page * 10, (page + 1) * 10).map(event => (
                          <Table.Row key={event.createdAt}>
                              <Table.Cell>{event.type}</Table.Cell>
                              <Table.Cell>{formatDate(event.createdAt)}</Table.Cell>
                              <Table.Cell>{event.user.email}</Table.Cell>
                              <Table.Cell textAlign="left">
                                  <Event event={event}/>
                              </Table.Cell>
                          </Table.Row>
                        ))}
                    </Table.Body>

                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan="4" textAlign="center">
                                <Menu pagination>
                                    {Array.from(Array(num).keys()).map(n => (
                                      <Menu.Item as="a" active={n === page}
                                                 onClick={() => setPage(n)}>{n + 1}</Menu.Item>
                                    ))}
                                </Menu>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </>
          )}

      </>
    )
}

export default AuditPos
