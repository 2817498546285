/** @jsxImportSource @emotion/react */
import React, { createRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useOrderPickingScan } from '../../OrderScanPicking'
import 'react-medium-image-zoom/dist/styles.css'
import slugify from 'slugify'
import { OrderItemRich } from '../OrderItemRich'
import { Divider } from 'semantic-ui-react'


const OrderItemsRich = ({
                            order,
                            orderItemsWithFulfillmentIssue,
                            orderItemsReplacements,
                            isModifying,
                            onAddOrderItemToFulfillmentIssues,
                            hideable,
                        }) => {
    const [checked, setChecked] = useState(new Set())

    const {pickingMode, scannedItemsMap} = useOrderPickingScan()

    useEffect(() => {
        setChecked(alreadyChecked => new Set([
            ...alreadyChecked,
            ...order.orderItems.filter(i => i.quantity === 0).map(i => i.id),
        ]))
    }, [order.orderItems])

    useEffect(() => {
        if (pickingMode === 'SCANNING') {
            const orderItemsWithFulfillmentIssueSetToZero = orderItemsWithFulfillmentIssue.filter(oi => oi.quantity === 0)
            if (orderItemsWithFulfillmentIssueSetToZero.length > 0) {
                setChecked(alreadyChecked => new Set([
                    ...alreadyChecked,
                    ...orderItemsWithFulfillmentIssueSetToZero.map(i => i.id),
                ]))
            }
        }
    }, [orderItemsWithFulfillmentIssue, pickingMode])

    function createStateFromOrder(
      orderItems,
      scannedItemsMap,
      orderItemsWithFulfillmentIssue,
      currentMap = null) {
        const copied = new Map(currentMap)
        scannedItemsMap = scannedItemsMap ?? new Map()
        orderItems = orderItems ?? []

        return orderItems.reduce((acc, item) => {
            const currentItem = copied.get(item.id)
            const currentScanned = scannedItemsMap.get(item.id) || 0
            const currentFulfillmentIssue = orderItemsWithFulfillmentIssue.find(oi => oi.id === item.id)
            acc.set(item.id, {
                ref: currentItem?.ref || createRef(),
                scanned: currentScanned,
                quantity: item.quantity,
                fulfillmentIssue: currentFulfillmentIssue,
            })
            return acc
        }, new Map())
    }

    const [itemsState, setItemsState] = useState(
      createStateFromOrder(order?.orderItems || [], scannedItemsMap, orderItemsWithFulfillmentIssue ?? []),
    )

    useEffect(() => {
        setItemsState((prev) => {
            return createStateFromOrder(
              order.orderItems || [], scannedItemsMap,
              orderItemsWithFulfillmentIssue || [], prev)
        })
    }, [order?.orderItems, scannedItemsMap, orderItemsWithFulfillmentIssue])

    useEffect(() => {
        if (pickingMode === 'SCANNING') {
            const fullyScannedItems = Array.from(itemsState.entries()).filter(([id, item]) => item.scanned === item.quantity).map(([id]) => id)
            setChecked(alreadyChecked => new Set([...alreadyChecked, ...fullyScannedItems]),
            )
        }
    }, [itemsState, pickingMode])

    return (
      <>
          {order.orderItems.map((orderItem) => {
              const oiFulfillmentIssue = orderItemsWithFulfillmentIssue.find(fi => fi.id === orderItem.id)
              const oiQuantity = oiFulfillmentIssue?.quantity ?? orderItem.quantity
              const key = `${orderItem.id}-${oiQuantity}-${slugify(orderItem.name, {
                  lower: true,
                  strict: true,
                  locale: 'fr',
              })}`
              const relatedReplacement = orderItemsReplacements.find(op => op.fulfillmentIssueParentId === orderItem.id)

              if (orderItem.fulfillmentIssueParentId) {
                  return null
              }

              return (
                <>
                    <OrderItemRich
                      key={key}
                      orderItem={orderItem}
                      isModifying={isModifying}
                      hideable={hideable}
                      isChecked={checked.has(orderItem.id)}
                      onCheck={() => setChecked(prev => {
                          const copy = new Set(prev)
                          if (copy.has(orderItem.id))
                              copy.delete(orderItem.id)
                          else
                              copy.add(orderItem.id)
                          return copy
                      })}
                      scanInfo={{
                          isScanned: scannedItemsMap.has(orderItem.id),
                          scannedQuantity: scannedItemsMap.get(orderItem.id),
                      }}
                      onAddOrderItemToFulfillmentIssues={onAddOrderItemToFulfillmentIssues}
                      fulfillmentIssue={orderItemsWithFulfillmentIssue.find(fi => fi.id === orderItem.id)}
                      relatedReplacement={relatedReplacement}
                      order={order}
                    />
                    <Divider/>
                </>
              )
          })}
      </>
    )
}

OrderItemsRich.propTypes = {
    orderItemsWithFulfillmentIssue: PropTypes.array,
    order: PropTypes.object.isRequired,
}

export default OrderItemsRich
