/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { PerformanceReportConfigurationForm } from './PerformanceReportConfigurationForm'
import { Button, Form, Icon, Segment } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { notify } from 'react-notify-toast'
import useUser from '../../Shared/UserProvider/useUser'
import {
    deletePerformanceReportConfiguration,
    updatePerformanceReportConfiguration,
} from '../../../services/Provider/Provider.service'
import { useState } from 'react'

const PerformanceReportConfigurationUpdate = ({performanceReportConfiguration}) => {
    const [t] = useTranslation()
    const [performanceReportConfigurationUpdate, setPerformanceReportConfigurationUpdate] = useState(performanceReportConfiguration)

    const {user} = useUser()
    const queryClient = useQueryClient()
    const onError = () => {
        notify.show(t('global.anErrorOccurred'), 'error')
    }

    const onSuccess = async () => {
        notify.show(t('global.registerSuccess'), 'success')
        await queryClient.invalidateQueries({
            queryKey: ['providers', user.provider.id, 'performanceReportConfigurations'],
        })
    }

    const deleteMutation = useMutation({
        mutationFn: (performanceReportConfiguration) => deletePerformanceReportConfiguration(performanceReportConfiguration),
        onError,
        onSuccess,
    })

    const updateMutation = useMutation({
        mutationFn: (performanceReportConfiguration) => updatePerformanceReportConfiguration(performanceReportConfiguration),
        onError,
        onSuccess,
    })

    const isPending = deleteMutation.isPending || updateMutation.isPending

    const canUpdatePerformanceReportConfiguration =
      !isPending &&
      performanceReportConfigurationUpdate.recipients.length > 0 &&
      performanceReportConfigurationUpdate.posIds.length > 0 &&
      (performanceReportConfigurationUpdate.options.performance.display ||
        performanceReportConfigurationUpdate.options.bestsellers.display ||
        performanceReportConfigurationUpdate.options.counterPerformance.display)

    return (
      <Segment color="teal">
          <Form>
              <PerformanceReportConfigurationForm
                performanceReportConfiguration={performanceReportConfigurationUpdate}
                onChange={(newValues) => {
                    setPerformanceReportConfigurationUpdate(prev => ({
                        ...prev,
                        ...newValues,
                    }))
                }}
              />

              <div css={css`
                  text-align: right;
                  margin-top: 1rem;

                  & :last-child {
                      margin-right: 0;
                  }
              `}>
                  <Button
                    color="red"
                    type="button"
                    loading={isPending}
                    disabled={isPending}
                    onClick={() => deleteMutation.mutate(performanceReportConfiguration)}
                  >
                      <Icon name="delete"/>
                      {t('global.delete')}
                  </Button>
                  <Button
                    color="teal"
                    type="submit"
                    loading={isPending}
                    disabled={!canUpdatePerformanceReportConfiguration}
                    onClick={() => updateMutation.mutate(performanceReportConfigurationUpdate)}
                  >
                      <Icon name="save"/>
                      {t('global.save')}
                  </Button>
              </div>
          </Form>
      </Segment>
    )
}

export { PerformanceReportConfigurationUpdate }
