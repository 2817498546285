/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {Button, Dropdown} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {getMerchantItems} from '../../../services/Item/Item.service';
import {debounce} from 'lodash';
import { getCancellationSource } from '../../../services/http-client';
import { useScanning } from '../../../scanning/scanning.service';


const ItemSelector = ({
    placeholder,
    filters,
    excludedItemIds,
    onItemAdd,
    onItemChange,
    preloadedSearch=undefined,
    selectFirstAtLoad=false,
    clearable=true,
    searchFunction=null,
    itemRenderer=null,
    notFoundMessage=null
}) => {
    const [t] = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [items, setItems] = useState([]);
    const isFirstLoad = useRef(true);
    const page = useRef(1);
    const setPage = (p) => page.current = p;

    const retrieveItems = searchFunction ?? getMerchantItems
    const [hasMoreResult,setHasMoreResult] = useState(false);
    const [searchQuery,setSearchQuery] = useState(preloadedSearch);

    useScanning(async (barcodeEvent) => {
        setIsLoading(true);

        const merchantItems = await retrieveItems({
            search: barcodeEvent.value,
            unavailableUntil: null,
            ...filters,
            page: 1,
            size: 1,
            wasScanned:true
        }, null)

        if (merchantItems) {
            setItems(merchantItems.items)

            if (merchantItems.items.length === 1) {
                const [firstItem] = merchantItems.items
                setSelectedItem(firstItem)
                onItemChange?.(firstItem, 'SCAN')
            }
        }
        setIsLoading(false);
    })

    placeholder =  placeholder || t('item_selector.add_element');

    const dropdownRef = useRef(null);

    const cancellationSource = useRef(getCancellationSource());

    const loadItems = useCallback(
        async (cancellationSource,search,page) => {
            setIsLoading(true);
            const size = 15;

            try {
                const merchantItems = await retrieveItems({
                    search,
                    unavailableUntil: null,
                    ...filters,
                    page,
                    size
                },cancellationSource)

                if(cancellationSource?.isCancelled) return;



                if (merchantItems && merchantItems.items) {
                    setItems(prev => [...prev, ...merchantItems.items].filter((value, index, array) => array.findIndex(value2 => value2.id === value.id) === index))
                    setHasMoreResult(merchantItems.items.length >= size);
                    if(selectFirstAtLoad && isFirstLoad.current) {
                        const [firstItem] = merchantItems.items
                        setSelectedItem(firstItem)
                        onItemChange?.(firstItem,null)
                    }
                }
                // Don't set in finally to avoid issue in DEV env.
                isFirstLoad.current = false;
                setIsLoading(false)

            } catch (error) {
                console.log(error)
                isFirstLoad.current = false;
                setIsLoading(false)

            } 
        },
        [retrieveItems, filters,onItemChange,selectFirstAtLoad],
    )

    const triggerLoadItem = useCallback((search,page) => {
        cancellationSource.current.cancel();
        const cancellationSrc = getCancellationSource();
        cancellationSource.current = cancellationSrc
        loadItems(cancellationSrc,search,page);
        return cancellationSource.current
    },[loadItems]);

    useEffect(() => {
        const cancellationSrc = triggerLoadItem(preloadedSearch,1);

        return () => cancellationSrc.cancel();
    }, [triggerLoadItem,preloadedSearch]);

    const getItemsOptions = useCallback((items) => {
        let nonExcludedItems = items;
        if (excludedItemIds) {
            nonExcludedItems = items.filter(i => !excludedItemIds.includes(i.id));
        }
        return nonExcludedItems.map(i => ({
            ...(itemRenderer && itemRenderer(i)),
            text: i.object.name,
            value: i.id,
            key: i.id,
            barcode: i.object.barcode,
        }));
    },[excludedItemIds, itemRenderer]);

    const availableOptions = useMemo(() => {
        return [
            {
                text:placeholder,
                disabled:true,
            },
            ...getItemsOptions(items)
        ];
    },[getItemsOptions, items, placeholder])

    const onSelectedItemChange = useCallback((selectedItemId) => {
          if (!selectedItemId) {
              setSelectedItem(null)
          }

          const itemToSelect = items.find(i => i.id === selectedItemId)

          if (!itemToSelect) {
              setSelectedItem(null)
          } else {
              setSelectedItem(itemToSelect)
              setSearchQuery('')
          }

        
        const input = dropdownRef?.current?.ref?.current?.childNodes?.[0];
        setTimeout(() => { input?.blur(); },0)


          return { itemToSelect }
      }, [items])

    const handleOnSelectedItemChange = (selectedItemId) => {
        const {itemToSelect} = onSelectedItemChange(selectedItemId)
        onItemChange?.(itemToSelect, 'MANUAL')
    }


    const scrollHandler = useCallback((event) => {
        if(isLoading || !hasMoreResult) return;

        const list = event.target
        const currentPos = Math.round(list.scrollHeight - list.scrollTop)
        const isBottom = currentPos === list.clientHeight + 1

        if (isBottom && !isLoading) {
            setIsLoading(true);
            const nextPage = page.current + 1;
            setPage(nextPage)
            triggerLoadItem(searchQuery,nextPage)
        }
    }, [isLoading,hasMoreResult,searchQuery,triggerLoadItem])

    useEffect(() => {
        const dropDown = dropdownRef.current.ref.current.childNodes[3]
        dropDown.addEventListener('scroll', scrollHandler)

        return () => {
            dropDown.removeEventListener('scroll', scrollHandler)
        }

    }, [scrollHandler]);

    const handleAddItem = () => {
        if (!selectedItem) {
            return;
        }

        onItemAdd(selectedItem);
        setSelectedItem(null);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if(selectedItem) {
                e.preventDefault();
            }
            handleAddItem()
        }
    }

    const debounceSearch = useMemo(() => (
        debounce((searchQuery) => {
            setItems([])
            setPage(1)
            triggerLoadItem(searchQuery,1)

        }, 500)
    ), [triggerLoadItem]);

    const handleSearchChange = (_e, { searchQuery }) => {
        setSearchQuery(searchQuery);
        debounceSearch(searchQuery)
    }

    return (<div css={css`
      display: flex;
    `}>
        <Dropdown
            placeholder={placeholder}
            clearable={clearable}
            fluid
            searchQuery={searchQuery}
            search={(curr, searchQuery) => {
                return curr.filter(c => {
                    return !searchQuery || c.text.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 || (c.barcode && c.barcode.indexOf(searchQuery) !== -1);
                })
            }}
            onSearchChange={handleSearchChange}
            selection
            loading={isLoading}
            value={selectedItem ? selectedItem.id : 0}
            onChange={(_e, { value }) => handleOnSelectedItemChange(value)}
            onKeyPress={handleKeyPress}
            noResultsMessage={isLoading ?  t('item_selector.search_in_progress') :  (notFoundMessage ?? t('item_selector.no_item_found'))}
            options={availableOptions}
            scrolling

            ref={dropdownRef}
        />

        {onItemAdd &&
            <Button
                icon='plus'
                color='teal'
                disabled={!selectedItem}
                onClick={handleAddItem}
            />
        }
    </div>)
};

ItemSelector.propTypes = {
    placeholder: PropTypes.string,
    filters: function(props, propName,componentName,location,fullPropName) {
            // isRequired if searchFunction is not provided
            if(props['searchFunction'] && typeof(props['searchFunction']) === 'function' && props[propName] === undefined){
                return PropTypes.object.isRequired(props,propName,componentName,location,fullPropName)
            }
    },
    excludedItemIds: PropTypes.arrayOf(PropTypes.string),
    onItemAdd: PropTypes.func,
    onItemChange: PropTypes.func,
    selectFirstAtLoad: PropTypes.bool,
    clearable: PropTypes.bool,
    searchFunction: PropTypes.func,
    itemRenderer: PropTypes.func,
    preloadedSearch: PropTypes.string,
    notFoundMessage: PropTypes.string
}

export default ItemSelector;
