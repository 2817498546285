/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import alertShort from '../../assets/mp3/alertshort.mp3'
import { getSoundMode } from '../../devices/device.provider'

const SoundPlayer = ({playing, onEnded}) => {
    const [t] = useTranslation()
    const player = useRef()
    const [show, setShow] = useState(true)
    const [soundMode, setSoundMode] = useState(getSoundMode())

    useEffect(() => {
        setSoundMode(getSoundMode())
        if (player.current) {
            try {
                // Take precedence over the Android notification
                if (window.Android?.stopNotifications) {
                    window.Android?.stopNotifications()
                }

                if (playing) {
                    player.current.volume = 1.0
                    player.current.muted = false
                    player.current.play()
                } else {
                    player.current.pause()
                }

            } catch (e) {
            }
        }
    }, [playing])


    const shouldRepeat = soundMode !== 'SHORT'
    useEffect(() => {
        const handler = () => {
            onEnded?.()
        }
        const currentPlayer = player.current
        if (!shouldRepeat && currentPlayer) {
            currentPlayer.addEventListener('ended', handler)
            return () => {
                currentPlayer.removeEventListener('ended', handler)
            }
        }

    }, [onEnded, playing, shouldRepeat])

    return (
      <>
          {show && (!window.Android?.getVersion && !window.webkit?.messageHandlers) && (
            <div css={css`
                width: 100%;
                height: 100%;
                position: fixed;
                top: 0;
                left: 0;
                background-color: #000;
                opacity: 0.8;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 99999999;
            `}>
                <Button color="red" onClick={() => {
                    player.current.play()
                    setTimeout(() => {
                        player.current.pause()
                    }, 1)
                    setShow(false)
                }}>{t('global.sound_on')}</Button>
            </div>
          )}

          <audio ref={player} src={alertShort} loop={shouldRepeat} controls={false}/>
      </>
    )
}

SoundPlayer.propTypes = {
    playing: PropTypes.bool.isRequired,
    onEnded: PropTypes.func,
}

export default SoundPlayer
