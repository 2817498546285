/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Input, Form, Grid, Table } from 'semantic-ui-react'
import { notify } from 'react-notify-toast';

import { useTranslation } from 'react-i18next';
import { mergeFile } from '../../services/Category/Category.service';

export const CategoryMergeFile = ({ setId, afterSubmit }) => {
  const [t] = useTranslation();

  const [loading, setLoading] = useState(false);

  const fileRef = useRef()
  const [file, setFile] = useState(null);

  const [latestResult,setLatestResult] = useState(null);
  const handleSubmit = async () => {
    setLoading(true)
    const bodyFormData = new FormData();
    bodyFormData.append('datafile', file[0]);

    try {
      setLatestResult(null);
      const result = await mergeFile(setId, bodyFormData);
      setLatestResult(result);
      console.log(result);
      notify.show(t('global.registerSuccess'), 'success');
    } catch (e) {
      setLatestResult(null);
      notify.show(t('global.anErrorOccurred'), 'error');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form>
      <Grid css={css`
                .column { padding-top: .5rem !important; padding-bottom: .5rem !important; }
              `}>
        <Grid.Column width={16}>
        <Form.Field css={css`margin-top: 20px;`}>
                                <label>{t('product_list.import.file')}</label>
                            </Form.Field>
                            <Form.Field>
                                <input type="file" ref={fileRef} onChange={() => {
                                    setFile(fileRef.current.files)
                                }} />
                            </Form.Field>
        </Grid.Column>
        {(latestResult && <Grid.Column  width={16}>
          <div>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Sous-Menu</Table.HeaderCell>
                  <Table.HeaderCell>Catégorie</Table.HeaderCell>
                  <Table.HeaderCell>Nombre d'article ajoutés</Table.HeaderCell>
                </Table.Row>
              
              </Table.Header>
              <Table.Body>
                {latestResult.inserted.map(t => (<Table.Row>
                  <Table.Cell>{t.category.slotName}</Table.Cell>
                  <Table.Cell>{t.category.name}</Table.Cell>
                  <Table.Cell>{t.insertedCount}</Table.Cell>
                </Table.Row>))}
              </Table.Body>
            </Table>
          </div>
        </Grid.Column>
        )}
        <Grid.Column width={16}>
          <div style={{ textAlign: 'right', marginTop: '10px' }}>
            {(! latestResult && <Button type="submit" color="teal" loading={loading} disabled={loading} onClick={handleSubmit}>Importer</Button>)}
            {(latestResult && <Button type="button" loading={loading} disabled={loading} onClick={afterSubmit}>Fermer</Button>)}
          </div>
        </Grid.Column>
      </Grid>
    </Form>
  )
}

CategoryMergeFile.propTypes = {
  setId: PropTypes.string.isRequired,
};
