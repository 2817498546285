/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {useContext} from 'react';
import {Table} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import slugify from 'slugify';
import OrderItemsRich from '../OrderItemsRich/OrderItemsRich';
import {UltyModalWrapperContext} from '../../../../Shared/UltyModalWrapper/UltyModalWrapperContext';
import OrderItemRow from '../OrderItemRow';
import { UltyMoney } from '@ulty/ulty-ui';
import useUser from '../../../../Shared/UserProvider/useUser';


const DiscountPriceRow = ({discountPrice}) => {
    const [t] = useTranslation();
    const {user} = useUser();
    const greenBold = {
        color: 'green',
        fontWeight: 'bold'
    };

    return (
        <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell />
            <Table.HeaderCell textAlign="right" style={greenBold} colSpan='2'>
                {t('order.discount')}
                <UltyMoney
                    readonly
                    amount={discountPrice}
                    currencyCode={user.getCompanyCurrency()}
                    locale={user.getCompanyLocale()}
                />
            </Table.HeaderCell>
        </Table.Row>
    )
}

const OrderItemsTable = ({ order, orderItemsWithFulfillmentIssue, orderItemsReplacements, isModifying, onAddOrderItemToFulfillmentIssues }) => {
    const [t] = useTranslation();
    const {user} = useUser();

    const {handleUltyModalWrapper} = useContext(UltyModalWrapperContext);

    const defineProductViewModal = (items) => {
        const modalSettings = {
            title: t('order.product_details'),
            component: <OrderItemsRich
                order={items}
                afterSubmit={() => handleUltyModalWrapper(false, null)}
                orderItemsWithFulfillmentIssue={orderItemsWithFulfillmentIssue}
                orderItemsReplacements={orderItemsReplacements}
                isModifying={false}
                hideable={false}
            />
        };

        handleUltyModalWrapper(true, modalSettings);
    };

    const sumQuantities = () => order.orderItems.reduce((total, orderItem) => total + orderItem.quantity, 0);

    const sumFulfillmentQuantities = () => {
        return order.orderItems.reduce((total, orderItem) => {
            const fulfillmentIssue = orderItemsWithFulfillmentIssue.find(oi => oi.id === orderItem.id);
            if (fulfillmentIssue) {
                total += +fulfillmentIssue.quantity;
            } else {
                total += orderItem.quantity;
            }

            return total;
        }, 0);
    }

    const sumProducts = () => order.orderItems.reduce((total, orderItem) => total + orderItem.quantity * orderItem.unitPrice, 0);

    const sumFulfillmentProducts = () => {
        let amount = 0.0;
        (order.orderItems || []).filter(op => !op.fulfillmentIssueParentId).forEach(op => {
            const [fulfillmentIssue] = orderItemsWithFulfillmentIssue.filter(oi => oi.id === op.id);
            if (fulfillmentIssue) {
                amount += parseInt(fulfillmentIssue.quantity) * op.unitPrice;
            } else {
                amount += op.quantity * op.unitPrice;
            }
        });

        (orderItemsReplacements || []).forEach(op => {
            amount += op.quantity * op.unitPrice;
        });

        return amount;
    }

    const shouldDisplayDiscountPrice = () => {
        return orderItemsWithFulfillmentIssue?.length === 0 && order?.discountPrice > 0;
    }

    return (
            <Table size='small'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('order.products')} ({order.nbProducts})</Table.HeaderCell>
                        {(orderItemsWithFulfillmentIssue.length > 0 || isModifying) && (<Table.HeaderCell>{t('order.fulfillment_issue.fulfillment_issue')}</Table.HeaderCell>)}
                        <Table.HeaderCell textAlign="right" css={css`
                          min-width: 80px;
                        `}>{t('order.quantity')}</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right" css={css`
                          min-width: 80px;
                        `}>{t('order.price')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body key={order.id}>
                    {order.orderItems.filter(oi => !oi.fulfillmentIssueParentId).map(oi => {
                        const oiFulfillmentIssue = orderItemsWithFulfillmentIssue.find(fi => fi.id === oi.id)
                        const oiQuantity = oiFulfillmentIssue?.quantity
                        const key = `${oi.id}-${oiQuantity}-${slugify(oi.name, {
                            lower: true,
                            strict: true,
                            locale: 'fr'
                        })}`

                        return (
                          <OrderItemRow
                            key={key}
                            order={order}
                            orderItem={oi}
                            onOrderItemDetailsPreview={() => defineProductViewModal(order)}
                            isModifying={isModifying}
                            hasFulfillmentIssues={orderItemsWithFulfillmentIssue.length > 0}
                            addOrderItemToFulfillmentIssues={onAddOrderItemToFulfillmentIssues}
                            fulfillmentReplacement={orderItemsReplacements.find(op => op.fulfillmentIssueParentId === oi.id)}
                            {...oiQuantity !== undefined ? { currentQuantity: oiQuantity } : {}}
                          />
                        )
                    })}
                </Table.Body>

                <Table.Footer>
                    {shouldDisplayDiscountPrice() &&
                        <DiscountPriceRow discountPrice={order.discountPrice} />
                    }
                    <Table.Row css={css`
                      s, strike {
                        text-decoration: none;
                        position: relative;
                        font-size: .8em;
                      }

                      s::before, strike::before {
                        top: 50%;
                        background: red;
                        opacity: .7;
                        content: '';
                        width: 110%;
                        position: absolute;
                        height: .2em;
                        border-radius: .2em;
                        left: -5%;
                        white-space: nowrap;
                        display: block;
                        transform: rotate(-15deg);
                      }

                      s.straight::before, strike.straight::before {
                        transform: rotate(0deg);
                        left: -1%;
                        width: 102%;
                      }

                      th {
                        text-decoration: ${orderItemsWithFulfillmentIssue.length > 0 && isModifying ? 'line-through' : 'none'};
                      }
                    `}>
                        <Table.HeaderCell>{t('order.total')}</Table.HeaderCell>
                        { (orderItemsWithFulfillmentIssue.length > 0 || isModifying) && (<Table.HeaderCell />)}
                        <Table.HeaderCell textAlign="right" css={css`font-weight: bold !important;`}>
                            {`${sumQuantities()}x`}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right" style={{
                            color: order.price !== sumProducts() ? 'red' : 'black',
                            fontWeight: 'bold',
                        }}>
                            {order.discountPrice && order.discountPrice > 0 ? (
                                <div css={css`
                                  display: flex;
                                  align-items: center;
                                  justify-content: flex-end;
                                  & > *:not(:last-child) {
                                    margin-right: 1rem !important;
                                  }
                                `}>
                                    <s>
                                        <UltyMoney
                                            readonly
                                            amount={order.price}
                                            currencyCode={user.getCompanyCurrency()}
                                            locale={user.getCompanyLocale()}
                                        />
                                    </s>
                                    <UltyMoney
                                        css={css`margin: 0`}
                                        readonly
                                        amount={order.price - order.discountPrice}
                                        currencyCode={user.getCompanyCurrency()}
                                        locale={user.getCompanyLocale()}
                                    />
                                </div>
                            ) : (
                                <UltyMoney
                                    readonly
                                    amount={order.price}
                                    currencyCode={user.getCompanyCurrency()}
                                    locale={user.getCompanyLocale()}
                                />
                            )}
                        </Table.HeaderCell>
                    </Table.Row>
                    { orderItemsWithFulfillmentIssue.length > 0 && isModifying && (
                        <Table.Row css={css`
                          th { border-top: none !important; }
                        `}>
                            <Table.HeaderCell>{t('order.new_total')}</Table.HeaderCell>
                            <Table.HeaderCell />
                            <Table.HeaderCell textAlign="right" css={css`font-weight: bold !important;`}>
                                {`${sumFulfillmentQuantities()}x`}
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="right" style={{
                                color: 'black',
                                fontWeight: 'bold',
                            }}>
                                <UltyMoney
                                    readonly
                                    amount={sumFulfillmentProducts()}
                                    currencyCode={user.getCompanyCurrency()}
                                    locale={user.getCompanyLocale()}
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    )}
                </Table.Footer>
            </Table>
    )
}

export default OrderItemsTable;
