import {useState} from 'react';
import { Tab, Divider, Form, Loader } from 'semantic-ui-react'
import useUser from '../../Shared/UserProvider/useUser';
import { getMerchantsOfCompany } from '../../../services/Company/Company.service';
import OptionsForm from './OptionsForm';
import { useTranslation } from 'react-i18next';
import AuditPos from './AuditPos';
import { UltyDropdown } from '@ulty/ulty-ui';
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getPointOfSale } from '../../../services/PointOfSale/PointOfSale.service'

const AdminHours = () => {
    const [t] = useTranslation();
    const { user } = useUser()
    const queryClient = useQueryClient()
    const [selectedPosId, setSelectedPosId] = useState()

    const { data: pointOfSales, isLoading } = useQuery({
        queryKey: ['pointofsales'],
        queryFn: () => getMerchantsOfCompany(user.provider.type === 'COMPANY' ? user.provider.object.id : user.provider.object.company.id)
    })

    const firstOrSelectedPosId = selectedPosId || pointOfSales?.[0]?.id
    const { data: selectedPos } = useQuery({
        queryKey: ['pointofsales', firstOrSelectedPosId],
        queryFn: () => getPointOfSale(firstOrSelectedPosId),
        enabled: !!firstOrSelectedPosId,
    })

    const handleSelectedPosChange = (e, { value }) => {
        const newSelection = pointOfSales.find(pos => pos.id === value)
        queryClient.setQueryData(['pointofsales', value], newSelection)
        setSelectedPosId(newSelection.id)
    }

    if (!pointOfSales) {
        return <Loader active />
    }

    return (
        <Tab.Pane>
            <Form>
                {user.provider.type === 'COMPANY' && (
                    <>
                        <Form.Field>
                            <UltyDropdown
                                label={t('global.merchant')}
                                fluid
                                selection
                                loading={isLoading}
                                value={selectedPos?.id || pointOfSales[0].id}
                                search
                                name="pos"
                                multiple={false}
                                onChange={handleSelectedPosChange}
                                options={pointOfSales.map(pos => ({
                                    text: pos.name,
                                    value: pos.id,
                                    key: pos.id
                                }))}
                            />
                        </Form.Field>
                        <Divider />
                    </>
                )}

                {selectedPos && (
                    <OptionsForm pointOfSale={selectedPos} />
                )}
            </Form>

            {user.isAdmin() && selectedPos && (
                <AuditPos pos={selectedPos} />
            )}
        </Tab.Pane>
    )
}

export default AdminHours
