import {rawCall,withIdempotencyKey} from '../services/http-client';



const call = async (method, path, data,options=null) => {
  try {
    return (await rawCall(method.toLowerCase(),path,"",data,options)).data
  } catch(e) {
    console.error(e)
  }

  return { status: 'ERROR' }
}

export const forgotPassword = async (email, captcha) => {
  return call('post', `/auth/forgot`, { email, captcha }, withIdempotencyKey())
}

export const checkResetKey = async (k) => {
  return call('post', `/auth/check-reset`, { k }, withIdempotencyKey())
}

export const resetPassword = async (k, password) => {
  return call('post', `/auth/reset`, { k, password }, withIdempotencyKey())
}

export const getCurrentOrders = async () => {
  return call('get', '/order/current')
}

export const getMerchant = async () => {
  return call('get', `/pointofsales`)
}

export const updateOrderStatus = (id, status) => {
  return call('put', `/order/${id}`, {
    status
  })
}
