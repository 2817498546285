/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { PerformanceReportConfigurationForm } from './PerformanceReportConfigurationForm'
import { Button, Form, Icon, Segment } from 'semantic-ui-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useUser from '../../Shared/UserProvider/useUser'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createPerformanceReportConfiguration } from '../../../services/Provider/Provider.service'
import { notify } from 'react-notify-toast'


const PerformanceReportConfigurationCreate = () => {
    const [t] = useTranslation()
    const {user} = useUser()

    const posIds = user.provider.type === 'COMPANY' ? [] : [user.provider.object.id]
    const initialPerformanceReportConfiguration = {
        providerId: user.provider.id,
        recipients: [user.email],
        cronExpression: '0 10 * * 1',
        posIds,
        options: {
            performance: {
                display: true,
            },
            bestsellers: {
                display: true,
                count: 5,
            },
            counterPerformance: {
                display: false,
            },
        },
    }

    const [performanceReportConfiguration, setPerformanceReportConfiguration] = useState(initialPerformanceReportConfiguration)

    const queryClient = useQueryClient()
    const createMutation = useMutation({
        mutationFn: (performanceReportConfigurationToCreate) => createPerformanceReportConfiguration(performanceReportConfigurationToCreate),
        onError: () => {
            notify.show(t('global.anErrorOccurred'), 'error')
        },
        onSuccess: async () => {
            notify.show(t('global.registerSuccess'), 'success')
            await queryClient.invalidateQueries({
                queryKey: ['providers', user.provider.id, 'performanceReportConfigurations'],
            })
            setPerformanceReportConfiguration(initialPerformanceReportConfiguration)
        },
    })

    const canCreatePerformanceReportConfiguration =
      performanceReportConfiguration.recipients.length > 0 &&
      performanceReportConfiguration.posIds.length > 0 &&
      (performanceReportConfiguration.options.performance.display ||
        performanceReportConfiguration.options.bestsellers.display ||
        performanceReportConfiguration.options.counterPerformance.display)

    return (
      <Segment color="teal">
          <Form>
              <PerformanceReportConfigurationForm
                performanceReportConfiguration={performanceReportConfiguration}
                onChange={(newValues) => {
                    setPerformanceReportConfiguration(prev => ({
                        ...prev,
                        ...newValues,
                    }))
                }}
              />
              <div css={css`
                  text-align: right;
                  margin-top: 1rem;

                  & :last-child {
                      margin-right: 0;
                  }
              `}>
                  <Button
                    color="teal"
                    type="submit"
                    loading={createMutation.isPending}
                    disabled={!canCreatePerformanceReportConfiguration}
                    onClick={() => createMutation.mutate(performanceReportConfiguration)}
                  >
                      <Icon name="add"/>
                      {t('global.add')}
                  </Button>
              </div>
          </Form>
      </Segment>
    )
}

export { PerformanceReportConfigurationCreate }
